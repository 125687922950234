import React from 'react';
import { Box } from '@chakra-ui/react';
import { ContactUs } from '../organisms/ContactUs';
import { Layout } from '../templates';
import { useSettings } from '../../stateManagement/SettingsContext';

export const ContactUsPage: React.FC = () => {
  const { settings, contactUsFormData } = useSettings(); // Destructure from context
  return (
    <Layout>
      <Box backgroundColor={settings?.configs.secondaryBgColor}>
        <ContactUs FormFields={contactUsFormData} />
      </Box>
    </Layout>
  );
};
