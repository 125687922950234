import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { FaComment, FaPlusCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { CustomInputEmail } from '../molecules/CustomInputEmail';
import { CustomInputTextbox } from '../molecules/CustomInputTextbox';
import { CustomInputTel } from '../molecules/CustomInputTel';
import { CustomInputDate } from '../molecules/CustomInputDate';
import { CustomInputPassword } from '../molecules/CustomInputPassword';
import { CustomInputNumber } from '../molecules/CustomInputNumber';
import { CustomInputFile } from '../molecules/CustomInputFile';
import { CustomInputRadio } from '../molecules/CustomInputRadio';
import { CustomCheckbox } from '../atoms/CustomCheckbox';
import { CustomHeading } from '../atoms/CustomHeading';
import { CustomButton } from '../atoms';
import { FormField } from '../../stateManagement/SettingsContext';
import { HelpshiftWidget } from '../atoms/HelpshiftWidget';

interface ContactUsProps {
  FormFields: FormField[] | null; // Array of form fields
}

export const ContactUs: React.FC<ContactUsProps> = ({ FormFields }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  const handleInputChange = (name: string, value: string) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFileChange = (files: Record<string, File>) => {
    setFormData(prevData => ({
      ...prevData,
      ...files, // Merge the new files into the existing form data
    }));
  };

  const handleLiveChatClick = () => {
    setIsWidgetOpen(true);
    // setTimeout(() => {
    //   setIsWidgetOpen(false);
    //   console.log(isWidgetOpen, 'widgetopen');
    // }, 2000);
    // console.log(isWidgetOpen, 'widgetopen');
  };

  const handleCancelClick = () => {
    navigate('/playerhub');
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // eslint-disable-next-line
    console.log('Form Data:', formData); // Log the form data
  };

  const renderInputField = (field: FormField, index: number) => {
    const { type, question, description, placeholder, required, multiple, options, height } = field;

    switch (type) {
      case 'heading':
        return (
          <Box key={index} marginBottom="2rem">
            <CustomHeading
              question={question}
              description={description}
              fontSize="4xl"
              descriptionFontSize="md"
              fontWeight="400"
              marginTop="2rem"
            />
          </Box>
        );
      case 'text':
        return (
          // <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
          <CustomInputTextbox
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            marginTop="2rem"
            // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
            // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
            fontSize="md"
            descriptionFontSize="sm"
            height={height || '50px'}
            onChange={e => handleInputChange(question, e.target.value)}
            value={formData[question] || ''}
          />
          // </div>
        );
      case 'password':
        return (
          // <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
          <CustomInputPassword
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            marginTop="2rem"
            // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
            // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
            fontSize="md"
            descriptionFontSize="sm"
            onChange={e => handleInputChange(question, e.target.value)}
            value={formData[question] || ''}
          />
          // </div>
        );
      case 'email':
        return (
          // <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
          <CustomInputEmail
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            marginTop="2rem"
            // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
            // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
            fontSize="md"
            descriptionFontSize="sm"
            onChange={e => handleInputChange(question, e.target.value)}
            value={formData[question] || ''}
          />
          // </div>
        );
      case 'date':
        return (
          <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
            <CustomInputDate
              question={question}
              placeholder={placeholder}
              required={required}
              description={description}
              marginTop="2rem"
              // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
              // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
              fontSize="md"
              descriptionFontSize="sm"
              onChange={e => handleInputChange(question, e.target.value)}
              value={formData[question] || ''}
            />
          </div>
        );
      case 'tel':
        return (
          <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
            <CustomInputTel
              question={question}
              placeholder={placeholder}
              required={required}
              description={description}
              marginTop="2rem"
              // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
              // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
              fontSize="md"
              descriptionFontSize="sm"
              onChange={e => handleInputChange(question, e.target.value)}
              value={formData[question] || ''}
            />
          </div>
        );
      case 'number':
        return (
          <div key={index} style={{ flex: '1 1 45%', margin: '0.5rem' }}>
            <CustomInputNumber
              question={question}
              placeholder={placeholder}
              required={required}
              description={description}
              marginTop="2rem"
              // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
              // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
              fontSize="md"
              descriptionFontSize="sm"
            />
          </div>
        );
      case 'checkbox':
        return (
          <div style={{ flex: '1 1 45%', margin: '0.5rem' }}>
            <CustomCheckbox
              question={question}
              isRequired={required}
              marginTop="2rem"
              // paddingLeft={{ base: '3rem', md: '5rem', lg: '9rem' }}
              // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
              onChange={e => handleInputChange(question, e.target.value)}
              value={formData[question] || ''}
            />
          </div>
        );
      case 'file':
        return (
          // <div style={{ flex: '1 1 45%', margin: '0.5rem' }}>
          <CustomInputFile
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            multiple={multiple}
            marginTop="2rem"
            // paddingLeft={{ base: '1rem', md: '2rem', lg: '4rem' }}
            // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
            fontSize="md"
            descriptionFontSize="sm"
            onChange={handleFileChange}
          />
          // </div>
        );
      case 'radio':
        return (
          // <div style={{ flex: '1 1 45%', margin: '0.5rem' }}>
          <CustomInputRadio
            question={question}
            placeholder={placeholder}
            required={required}
            description={description}
            options={options}
            marginTop="2rem"
            // paddingLeft={{ base: '1rem', md: '2rem', lg: '4rem' }}
            // marginStart={{ base: '1rem', sm: '1rem', xl: '1rem' }}
            fontSize="md"
            descriptionFontSize="sm"
            onChange={e => handleInputChange(question, e.target.value)}
            value={formData[question] || ''}
          />
          // </div>
        );
      default:
        return null;
    }
  };

  const headingFields = FormFields?.filter(field => field.type === 'heading') || [];
  const otherFields = FormFields?.filter(field => field.type !== 'heading') || [];

  const firstColumnFields = otherFields.slice(0, 4); // First 4 fields for the first column
  const secondColumnFields = otherFields.slice(4, 6);

  return (
    <Box padding="2rem" maxWidth="1200px" margin="0 auto">
      <form onSubmit={handleSubmit}>
        {/* Render headings outside the flex arrangement */}
        {headingFields.map((field, index) => renderInputField(field, index))}

        <Box
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          gap="2rem"
          justifyContent="space-between"
        >
          {/* First Column */}
          <Box flex="1">
            {firstColumnFields.map((field, index) => (
              <Box marginBottom="1rem">{renderInputField(field, index)}</Box>
            ))}
          </Box>
          {/* Second Column */}
          <Box flex="1">
            {secondColumnFields.map((field, index) => (
              <Box marginBottom="1rem">{renderInputField(field, index)}</Box>
            ))}
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" marginTop="2rem">
          <CustomButton
            type="submit"
            variant="solid"
            size="lg"
            colorScheme="blackAlpha"
            textColor="white"
            backgroundColor="black"
            padding="12px 24px"
            icon={<FaPlusCircle />}
            style={{ marginBottom: '16px', width: '450px' }}
          >
            Create Ticket
          </CustomButton>
          <CustomButton
            // onClick={() => console.log('Live chat started')}
            onClick={handleLiveChatClick}
            variant="solid"
            size="lg"
            colorScheme="blackAlpha"
            textColor="white"
            backgroundColor="black"
            padding="12px 24px"
            // icon={<FaCommentDots />}
            icon={<FaComment />}
            style={{ marginBottom: '16px', width: '450px' }}
          >
            Live Chat
          </CustomButton>
          {isWidgetOpen && (
            <HelpshiftWidget
              PLATFORM_ID="ravi-demo_platform_20220331005029753-d9ae2b34048d999"
              DOMAIN="ravi-demo"
              openWidget
            />
          )}
          <CustomButton
            onClick={handleCancelClick}
            variant="outline"
            size="lg"
            textColor="black"
            backgroundColor="white"
            border="1px solid"
            borderColor="black"
            padding="12px 43px"
            style={{ marginBottom: '32px', width: '450px' }}
          >
            Cancel
          </CustomButton>
        </Box>
      </form>
    </Box>
  );
};
