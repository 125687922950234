import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import tinycolor from 'tinycolor2';
import { useSettings } from '../../stateManagement/SettingsContext';
import { Layout } from '../templates';
import { ContactBtnSection } from '../molecules/ContactUsBtnSection';
import { SearchBar } from '../organisms/SearchBar';
import { CustomText } from '../atoms';
import { RelatedSections } from '../organisms/RelatedSections';
import { Pagination } from '../molecules/Pagination';

const ITEMS_PER_PAGE = 10; // Number of FAQs per page

export const Section: React.FC = () => {
  const { settings, sectionsData, faqs } = useSettings();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const url = window.location.pathname;

  const sectionTitle = useMemo(() => {
    const match = url.match(/\/section\/([^/]+)/);
    return match ? decodeURIComponent(match[1]) : null;
  }, [url]);

  const language = useMemo(() => {
    const match = url.match(/\/sections\/([^/]+)/);
    return match ? match[1] : '';
  }, [url]);

  const lightenedBorderColor = useMemo(() => {
    return tinycolor(settings?.configs.secondaryTextColor).lighten(90).toString();
  }, [settings?.configs.secondaryTextColor]);

  const sectionToDisplay = useMemo(() => {
    return sectionTitle
      ? sectionsData?.find(
          section => section?.section_name.toLowerCase().replace(/\s+/g, '-') === sectionTitle,
        )
      : null;
  }, [sectionTitle, sectionsData]);

  const totalPages = useMemo(() => {
    return Math.ceil((sectionToDisplay?.faq_data.length || 0) / ITEMS_PER_PAGE);
  }, [sectionToDisplay]);

  const currentFaqs = useMemo(() => {
    return sectionToDisplay?.faq_data.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [sectionToDisplay, currentPage]);

  const handleArticleClick = (publishId: string) => {
    const faq = faqs?.find(item => item.publish_id === publishId);
    const translation = faq?.translations[language];
    if (translation) {
      const formattedTitle = translation.title.toLowerCase().replace(/\s+/g, '-');
      navigate(`/article/${language}/faq/${publishId}-${formattedTitle}`);
    }
  };

  useEffect(() => {
    if (sectionToDisplay) {
      window.scrollTo(0, 0);
    }
  }, [sectionToDisplay]);

  if (!sectionToDisplay) {
    return (
      <Box
        px={{ base: '2vw', md: '1vw', lg: '15vw' }}
        backgroundColor={settings?.configs.primaryBgColor}
        textAlign="center"
        py={8}
      >
        <Text fontSize="2xl" color={settings?.configs.secondaryTextColor}>
          404 - Article Not Found
        </Text>
        <Text fontSize="lg" color={settings?.configs.secondaryTextColor}>
          The requested article could not be found. Please check the URL or try again later.
        </Text>
      </Box>
    );
  }

  return (
    <Layout>
      <Box
        px={{ base: '2vw', md: '1vw', lg: '14vw' }}
        backgroundColor={settings?.configs.primaryBgColor}
        py={{ base: '2vh', lg: '2vh' }}
        overflow="visible"
      >
        <SearchBar searchBarTextColor={settings?.configs.secondaryTextColor} />

        <Flex mt={8} gap={8} flexDirection={{ base: 'column', lg: 'row' }}>
          <Box flex="2" backgroundColor="#fff" p={4}>
            <Breadcrumb separator="/" fontSize="xs" color="gray.600" p={4}>
              <BreadcrumbItem>
                <BreadcrumbLink href="/" color="blue.500">
                  Home
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink color="gray.700">{sectionToDisplay.section_name}</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>

            <Box my={6} p={4}>
              <CustomText variant="h1" fontSize="4xl">
                {sectionToDisplay.section_name}
              </CustomText>
            </Box>

            {currentFaqs?.map(faq => (
              <Box
                key={faq.publish_id}
                borderBottom={`1px solid ${lightenedBorderColor}`}
                p={4}
                _hover={{ boxShadow: 'md', transform: 'scale(1.01)' }}
                onClick={() => handleArticleClick(faq.publish_id)}
              >
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  color={settings?.configs.secondaryTextColor}
                  noOfLines={1}
                >
                  {faq.faq_title}
                </Text>
                <Box
                  dangerouslySetInnerHTML={{ __html: faq.faq_body }}
                  color={settings?.configs.secondaryTextColor}
                  fontSize="sm"
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                  }}
                />
              </Box>
            ))}

            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
          </Box>

          <Box
            flex="1"
            backgroundColor={settings?.configs.primaryBgColor}
            onClick={() => setCurrentPage(1)}
          >
            <Box mb={2}>
              <CustomText fontWeight="bold" variant="h1">
                Sections
              </CustomText>
            </Box>
            <RelatedSections
              sectionsData={sectionsData}
              bgColor={settings?.configs.secondaryBgColor}
              textColor={settings?.configs.secondaryTextColor}
            />
          </Box>
        </Flex>
      </Box>

      <Box marginTop="5em">
        <ContactBtnSection
          color="#ffffff"
          onButtonClick={() => navigate(`/contactUs`)}
          variant="h2"
        />
      </Box>
    </Layout>
  );
};
