import React from 'react';
import { Box, ResponsiveValue } from '@chakra-ui/react';
import { MainHeader } from '../molecules/MainHeader';
import { SubHeader } from '../molecules/SubHeader';

// Define props interface
interface HeaderProps {
  logoUrl: string; // URL for the logo
  gameTitle: string; // Title of the game
  platforms: string[]; // List of platforms
  languages: string[]; // List of available languages
  currentLanguage: string; // Current selected language
  onLanguageChange: (language: string) => void; // Handler for language change
  onClickMyTickets: () => void;
  horizontalPadding?: ResponsiveValue<string>; // Optional horizontal padding
  showHeader?: boolean;
  showMainHeader?: boolean;
  showSubHeader?: boolean;
  showHeaderLogo?: boolean;
  mainHeaderBgColor: string;
  subHeaderBgColor: string;
  subHeaderTextColor: string;
  mainHeaderTextColor: string;
  changeTheme?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  logoUrl,
  gameTitle,
  platforms,
  languages,
  currentLanguage,
  onLanguageChange,
  onClickMyTickets,
  horizontalPadding,
  showHeader = true,
  showMainHeader = true,
  showSubHeader = true,
  showHeaderLogo = true,
  mainHeaderBgColor,
  subHeaderBgColor,
  subHeaderTextColor,
  mainHeaderTextColor,
  changeTheme,
}) => {
  return (
    <Box>
      {
        /* Main Header Section */
        showHeader && showMainHeader && (
          <MainHeader
            changeTheme={changeTheme}
            showHeaderLogo={showHeaderLogo}
            logoUrl={logoUrl}
            logoSize={{ base: '30%', sm: '30%', md: '30%', lg: '15%' }}
            gameTitle={gameTitle}
            horizontalPadding={horizontalPadding}
            marginStart={{ base: '7%', lg: '5%' }}
            mainHeaderBgColor={mainHeaderBgColor}
            mainHeaderTextColor={mainHeaderTextColor}
          />
        )
      }
      {showHeader && showSubHeader && (
        <SubHeader
          textColor={subHeaderTextColor}
          platforms={platforms}
          currentLanguage={currentLanguage}
          languages={languages}
          onLanguageChange={onLanguageChange}
          horizontalPadding={horizontalPadding}
          subHeaderBgColor={subHeaderBgColor}
          subHeaderTextColor={subHeaderTextColor}
          onClickMyTickets={onClickMyTickets}
        />
      )}
    </Box>
  );
};
