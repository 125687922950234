import React, { ChangeEventHandler } from 'react';
import { FormControl, ResponsiveValue } from '@chakra-ui/react';
import { Label } from '../atoms/Label';
import { Description } from '../atoms/Description';
import { CustomInput } from '../atoms';

interface CustomInputTextboxProps {
  question: string;
  placeholder?: string;
  required: boolean;
  description?: string;
  marginStart?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  marginTop?: string;
  fontSize?: string;
  descriptionFontSize?: string;
  height?: ResponsiveValue<string>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
}

export const CustomInputTextbox: React.FC<CustomInputTextboxProps> = ({
  question,
  placeholder,
  required,
  description,
  marginStart,
  paddingLeft,
  fontSize,
  descriptionFontSize,
  height,
  onChange,
  value,
  marginTop,
}) => {
  const inputId = 'Textbox-field'; // Unique id for the input field

  return (
    <div>
      <FormControl marginStart={marginStart} paddingLeft={paddingLeft}>
        <Label label={question} htmlFor={inputId} required={required} fontSize={fontSize} />
        <Description description={description} fontSize={descriptionFontSize} />
        <CustomInput
          isRequired={required}
          placeholder={placeholder}
          onChange={onChange}
          size="md"
          value={value}
          type="text"
          isDisabled={false}
          variant="outline"
          bgColor="#FFFFFF"
          height={height || '50px'}
          marginTop={marginTop}
        />
      </FormControl>
    </div>
  );
};
