import React from 'react';
import { Th, HStack, IconButton } from '@chakra-ui/react';
import { FaSort, FaFilter } from 'react-icons/fa';

interface CustomTableHeadingProps {
  field: string;
  bgColor?: string;
  fontSize?: string | number;
  textColor?: string;
  borderColor?: string;
  onSort?: () => void;
  onFilter?: () => void;
  fieldsWithIcons?: string[];
  centerAlignFields?: string[];
  isSortIcon?: boolean;
  isFilterIcon?: boolean;
}

export const CustomTableHeading: React.FC<CustomTableHeadingProps> = ({
  field,
  bgColor = 'gray.300',
  fontSize = 'md',
  textColor = 'black',
  borderColor = 'gray.400',
  onSort,
  onFilter,
  fieldsWithIcons = [],
  centerAlignFields = [],
  isSortIcon = false,
  isFilterIcon = false,
}) => {
  const isCenterAlignedField = centerAlignFields.includes(field);
  return (
    <Th
      bg={bgColor}
      fontSize={fontSize}
      color={textColor}
      textAlign={isCenterAlignedField ? 'center' : 'left'}
      padding="0.5rem"
      borderTop={`2px solid ${borderColor}`}
      borderBottom={`2px solid ${borderColor}`}
      textTransform="none"
    >
      <HStack justify={isCenterAlignedField ? 'center' : 'flex-start'} spacing="0">
        <span>{field}</span>
        {fieldsWithIcons.includes(field) && (
          <HStack spacing="0">
            {isSortIcon && (
              <IconButton
                aria-label={`Sort by ${field}`}
                icon={<FaSort />}
                size="sm"
                onClick={onSort}
                variant="ghost"
                ml="0"
              />
            )}
            {isFilterIcon && (
              <IconButton
                aria-label={`Filter ${field}`}
                icon={<FaFilter />}
                size="sm"
                onClick={onFilter}
                variant="ghost"
                ml="0"
              />
            )}
          </HStack>
        )}
      </HStack>
    </Th>
  );
};
