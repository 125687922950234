import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Issue {
  id: number;
  description: string;
  status: 'open' | 'closed';
}

interface IssuesState {
  issues: Issue[];
}

const initialState: IssuesState = {
  issues: [],
};

const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    addIssue: (state, action: PayloadAction<Issue>) => {
      state.issues.push(action.payload);
    },
    closeIssue: (state, action: PayloadAction<number>) => {
      const issue = state.issues.find(iss => iss.id === action.payload);
      if (issue) {
        issue.status = 'closed';
      }
    },
  },
});

export const { addIssue, closeIssue } = issuesSlice.actions;
export const issuesReducer = issuesSlice.reducer;
