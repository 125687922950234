import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ArticleContent } from '../organisms/FaqPage/ArticleContent';
import { useSettings } from '../../stateManagement/SettingsContext';
import { Layout } from '../templates';
import { ContactBtnSection } from '../molecules/ContactUsBtnSection';
import { SearchBar } from '../organisms/SearchBar';
import { RelatedArticles } from '../organisms/FaqPage/RelatedArticles';
import { CustomText } from '../atoms';

export const Faq: React.FC = () => {
  const { settings, faqs } = useSettings();
  const navigate = useNavigate();

  // Function to extract FAQ ID from the URL
  function extractFaqId(url: string): string | undefined {
    const match = url.match(/\/faq\/(\d+)-/); // Match digits before the dash
    return match ? match[1] : undefined; // Return the captured number
  }

  const url = window.location.pathname;
  const faqId = extractFaqId(url);

  // Extract language from the URL
  const langMatch = url.match(/\/article\/([^/]+)/);
  const language = langMatch ? langMatch[1] : '';

  // Use useMemo to fetch the FAQ data based on faqId and language
  const faqToDisplay = useMemo(() => {
    return faqId ? faqs?.find(faq => faq.publish_id === faqId) : null;
  }, [faqId, faqs]);

  // Extract article content and title, with fallback values
  const articleContent = faqToDisplay?.translations?.[language]?.body || 'No content available.';
  const articleTitle = faqToDisplay?.translations?.[language]?.title || 'No Title Available';
  const sectionName = faqToDisplay?.section_translations?.[language]?.title || 'No Title Available';
  const lastUpdatedAt = faqToDisplay?.updated_at || '';
  const date = new Date(lastUpdatedAt);
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // Check if FAQ was found
  const notFound = !faqToDisplay || !articleContent;

  // Handle related article selection
  function onClickRelatedArticle(publish_id: string) {
    const faq = faqs?.find(item => item.publish_id === publish_id);
    if (faq) {
      const translation = faq.translations[language];
      if (translation) {
        const formattedTitle = translation.title.toLowerCase().replace(/\s+/g, '-');
        navigate(`/article/${language}/faq/${publish_id}-${formattedTitle}`);
      }
    }
  }

  // Scroll to top when FAQ is found
  useEffect(() => {
    if (!notFound) {
      window.scrollTo(0, 0);
    }
  }, [notFound]);

  // Render the error page if FAQ is not found
  if (notFound) {
    return (
      <Box
        px={{ base: '2vw', md: '1vw', lg: '15vw' }}
        backgroundColor={settings?.configs.primaryBgColor}
        textAlign="center"
        py={8}
      >
        <Text fontSize="2xl" color={settings?.configs.secondaryTextColor}>
          404 - Article Not Found
        </Text>
        <Text fontSize="lg" color={settings?.configs.secondaryTextColor}>
          The requested article could not be found. Please check the URL or try again later.
        </Text>
      </Box>
    );
  }

  // Render the FAQ content and related articles
  return (
    <Layout>
      <Box
        px={{ base: '2vw', md: '1vw', lg: '14vw' }}
        backgroundColor={settings?.configs.primaryBgColor}
        height="100%"
        py={{ base: '2vh', lg: '2vh' }}
        overflow="visible"
        borderTop="1px solid #ffffff"
      >
        <Box>
          <SearchBar searchBarTextColor={settings?.configs.secondaryTextColor} />
        </Box>
        <Flex mt={8} gap={8} flexDirection={{ base: 'column', lg: 'row' }}>
          <Box flex="2" shadow="md">
            <ArticleContent
              title={articleTitle}
              lastUpdated={formattedDate}
              content={articleContent}
              textColor={settings?.configs.secondaryTextColor}
              bgColor={settings?.configs.secondaryBgColor}
              sectionName={sectionName}
            />
          </Box>
          <Box flex="1" backgroundColor={settings?.configs.primaryBgColor}>
            <Box mb={2}>
              <CustomText fontWeight="bold" variant="h1">
                Sections
              </CustomText>
            </Box>
            <RelatedArticles
              bgColor={settings?.configs.secondaryBgColor}
              textColor={settings?.configs.secondaryTextColor}
              onClickRelatedArticle={publish_id => {
                onClickRelatedArticle(publish_id);
              }}
              faqId={faqId}
              faqTitle={articleTitle}
            />
          </Box>
        </Flex>
        <Box marginTop="5em">
          <ContactBtnSection
            color="#ffffff"
            onButtonClick={() => {
              navigate(`/contactUs`);
            }}
            variant={undefined}
          />
        </Box>
      </Box>
    </Layout>
  );
};
