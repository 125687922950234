import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BoxProps, Box, Flex, HStack, VStack, Icon, Button } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import tinycolor from 'tinycolor2';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { CustomText } from '../atoms';
import { getIconFromString } from './MenuCard';
import { useSettings } from '../../stateManagement/SettingsContext';

type SectionCardProps = BoxProps & {
  sectionName: string;
  icon: string;
  bgColor?: string;
  textColor?: string;
  iconColor?: string;
  faqs: {
    id?: string;
    publish_id: string;
    faq_title: string;
  }[];
  onClickMore: (formattedSectionTitle: string) => void;
};

export const SectionCard: React.FC<SectionCardProps> = ({
  sectionName,
  icon,
  bgColor,
  textColor,
  iconColor = '#000000',
  faqs,
  onClickMore,
}) => {
  const navigate = useNavigate();
  const lightenedBorderColor = tinycolor(textColor).lighten(90).toString();
  const { language } = useSettings();

  const onClickFaq = (publish_id: string) => {
    const faq = faqs.find(item => item.publish_id === publish_id);
    if (faq) {
      navigate(
        `/article/${language}/faq/${publish_id}-${faq?.faq_title.toLowerCase().replace(/\s+/g, '-')}`,
      );
    }
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="3"
      backgroundColor={bgColor}
      color={textColor}
      position="relative" // Ensures date can be positioned in bottom-right
      height="100%" // Ensure box takes full height for positioning
      boxShadow="md"
    >
      {/* Flexbox to align the title at the top */}
      <Flex direction="column" justify="space-between">
        <VStack
          cursor="pointer"
          align="start"
          borderBottom={`1px solid ${lightenedBorderColor}`}
          p={3}
        >
          <Icon
            as={getIconFromString(icon) || FaRegQuestionCircle}
            boxSize="8" // Increase the size (e.g., 8 is larger than the default size)
            color={iconColor} // Set a vibrant color (replace with your preferred color)
          />

          <CustomText fontSize="lg" fontWeight="bold">
            {sectionName}
          </CustomText>
        </VStack>
        {/* Map through faqs and slice based on a static limit of 3 */}
        {faqs?.slice(0, 3).map(faq => (
          <HStack
            justify="space-between"
            align="center"
            borderBottom={`1px solid ${lightenedBorderColor}`}
            p={4}
            _hover={{ boxShadow: 'md', transform: 'scale(1.05)' }}
            key={faq.publish_id} // Changed to use publish_id as key
            onClick={() => onClickFaq(faq.publish_id)}
          >
            {/* Push icon to the right */}
            <CustomText color={textColor} fontSize="sm" fontWeight="medium">
              {faq.faq_title}
            </CustomText>
            <ChevronRightIcon color={textColor} />
          </HStack>
        ))}

        {/* Show "More" button if there are more than 3 faqs */}
        {faqs?.length > 3 && (
          <HStack justify="flex-end" p={4}>
            <Button
              onClick={() => onClickMore(sectionName.toLowerCase().replace(/\s+/g, '-'))}
              size="sm"
              variant="link"
              colorScheme="teal"
            >
              More
            </Button>
            <ChevronRightIcon color={textColor} />
          </HStack>
        )}
      </Flex>
    </Box>
  );
};
