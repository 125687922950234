import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Login } from './components/pages/Login';
import { About, Home } from './components/pages';
import { ProtectedRoute } from './routes/ProtectedRoute';
import { useAuth } from './stateManagement/auth/useAuth';
import { LandingPage } from './components/pages/landingPage';
import { SettingsProvider } from './stateManagement/SettingsContext';
import { Faq } from './components/pages/Faq';
import { AdminPage } from './components/pages/AdminPage';
import { ContactUsPage } from './components/pages/ContactUsPage';
import { PlayerHubPage } from './components/pages/PlayerHubPage';
import { TicketPage } from './components/pages/TicketPage';
import { Section } from './components/pages/Section';

export const App: React.FC = () => {
  const { isAuthenticated } = useAuth();
  return (
    <Router>
      <SettingsProvider>
        <Routes>
          {/* Login Route */}
          <Route path="/login" element={<Login />} />

          {/* Protected Routes */}
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            {/* Main Landing Page */}
            <Route path="/" element={<LandingPage />} />

            {/* Other routes */}
            <Route path="/about" element={<About />} />
            <Route path="/home" element={<Home />} />
            <Route path="/article/*" element={<Faq />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/contactUs" element={<ContactUsPage />} />
            <Route path="/playerhub" element={<PlayerHubPage />} />
            <Route path="/tickethistory" element={<TicketPage />} />
            <Route path="/sections/*" element={<Section />} />
          </Route>
        </Routes>
      </SettingsProvider>
    </Router>
  );
};
