import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Stack,
  VStack,
  Heading,
  Text,
  Button,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { CustomText } from '../../atoms';
// import { FaPlus } from 'react-icons/fa';

// Interfaces for FAQ and Section
interface FAQ {
  publish_id: string;
  faq_title: string;
}

interface Section {
  section_id: string;
  section_name: string;
  faq_data: FAQ[];
}

interface FAQSelectionOrganismProps {
  sectionsData: Section[] | null;
  onSelectionChange: (selectedFaqIds: string[]) => void;
}

export const PopularArticlesSettingsMolecule: React.FC<FAQSelectionOrganismProps> = ({
  sectionsData,
  onSelectionChange,
}) => {
  const [selectedFaqIds, setSelectedFaqIds] = useState<string[]>([]);

  const handleCheckboxChange = (faqId: string, isChecked: boolean) => {
    if (isChecked) {
      if (selectedFaqIds.length < 6) {
        const updatedSelections = [...selectedFaqIds, faqId];
        setSelectedFaqIds(updatedSelections);
        onSelectionChange(updatedSelections);
      }
    } else {
      const updatedSelections = selectedFaqIds.filter(id => id !== faqId);
      setSelectedFaqIds(updatedSelections);
      onSelectionChange(updatedSelections);
    }
  };

  const [isExpanded, setIsExpanded] = useState(false); // State for expanding/collapsing cards

  // Toggle collapse and expand
  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box>
      {/* Section Header */}
      <HStack
        justifyContent="space-between"
        mb={4}
        border="1px solid #dedede"
        p="2"
        borderRadius="10px"
        backgroundColor="#f1f2f4"
      >
        <CustomText variant="body" size="md" fontWeight="bold">
          Popular Articles
        </CustomText>
        <HStack spacing={2}>
          {/* Collapse/Expand Button */}
          <Icon
            as={isExpanded ? ChevronUpIcon : ChevronDownIcon}
            boxSize={6}
            onClick={toggleExpandCollapse}
            cursor="pointer"
            aria-label={isExpanded ? 'Collapse' : 'Expand'}
          />
        </HStack>
      </HStack>
      {isExpanded && (
        <Box height="30vh" overflow="scroll">
          <Stack spacing={6}>
            {sectionsData?.map(section => (
              <Box key={section.section_id} borderBottom="1px solid #e2e8f0" pb={4}>
                <Heading size="sm" mb={2}>
                  {section.section_name}
                </Heading>
                <CheckboxGroup>
                  <VStack align="start" spacing={2}>
                    {section.faq_data.map(faq => (
                      <Checkbox
                        key={faq.publish_id}
                        value={faq.publish_id}
                        isChecked={selectedFaqIds.includes(faq.publish_id)}
                        isDisabled={
                          !selectedFaqIds.includes(faq.publish_id) && selectedFaqIds.length >= 6
                        }
                        onChange={e => handleCheckboxChange(faq.publish_id, e.target.checked)}
                      >
                        {faq.faq_title}
                      </Checkbox>
                    ))}
                  </VStack>
                </CheckboxGroup>
              </Box>
            ))}
          </Stack>
          <Stack direction="row" justifyContent="space-between" mt={6}>
            <Text fontWeight="medium">Selected FAQs: {selectedFaqIds.length} / 6</Text>
            <Button
              colorScheme="blue"
              onClick={() => {
                // console.log('Selected FAQs:', selectedFaqIds);
              }}
              isDisabled={selectedFaqIds.length === 0}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
