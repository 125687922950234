import { Icon as ChakraIcon, IconProps } from '@chakra-ui/react';
import React, { ElementType } from 'react';

interface CustomIconProps extends IconProps {
  icon: ElementType;
  size?: string;
  color?: string;
  padding?: string;
  bgColor?: string;
  handleOnClick?: () => void;
}

export const CustomIcon: React.FC<CustomIconProps> = ({
  icon: IconComponent,
  size = '24px',
  color,
  padding,
  handleOnClick,
  bgColor,
}) => {
  return (
    <ChakraIcon
      as={IconComponent}
      w={size}
      h={size}
      color={color}
      padding={padding}
      onClick={handleOnClick}
      bg={bgColor}
    />
  );
};
