/**
 * CustomThemeProvider component.
 *
 * This component wraps the application with the Chakra UI `ChakraProvider` and provides a custom theme.
 * The theme is either the default theme or a dynamically fetched theme based on the `clientId`.
 *
 * @param {CustomThemeProviderProps} props - Contains children components to render inside the provider.
 *
 * - Uses `fetchColors` to fetch custom theme colors asynchronously based on a `clientId`.
 * - Merges the fetched colors with the default theme configuration (including color mode settings).
 * - On fetch failure, it falls back to the default theme.
 */
import React, { useEffect, useState } from 'react';
import { ChakraProvider, extendTheme, ThemeConfig } from '@chakra-ui/react';
import { defaultTheme } from './defaultTheme';
import { fetchColors } from './fetchColors';

interface CustomThemeProviderProps {
  children: React.ReactNode;
}

const clientId = 'jets';

export const CustomThemeProvider: React.FC<CustomThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    const getColors = async () => {
      try {
        const fetchedColors = await fetchColors(clientId);

        // Merge fetched colors with default config
        const config: ThemeConfig = {
          initialColorMode: 'light',
          useSystemColorMode: false,
        };

        const newTheme = extendTheme({ config, colors: fetchedColors });
        setTheme(newTheme);
      } catch (error) {
        // Handle error (e.g., use default theme or show an error message)
        // eslint-disable-next-line no-console
        console.error('Failed to fetch colors, using default theme.');
      }
    };

    getColors();
  }, []);

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
