import React from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputProps,
  ResponsiveValue,
  Box,
} from '@chakra-ui/react';

interface CustomInputNumProps extends Omit<NumberInputProps, 'onChange'> {
  value?: number;
  step?: number;
  placeholder?: string;
  fontSize?: ResponsiveValue<string>;
  fontWeight?: ResponsiveValue<string>;
  color?: string;
  textAlign?: 'left' | 'right' | 'center' | 'justify';
  marginStart?: ResponsiveValue<string>;
  marginTop?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
  bgColor?: string;
  height?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  onChange?: (value: number) => void;
}

export const CustomInputNum: React.FC<CustomInputNumProps> = ({
  value = 0,
  step = 1,
  placeholder,
  fontSize,
  fontWeight,
  color = 'black',
  textAlign = 'left',
  marginStart,
  marginTop = '1rem',
  padding,
  isDisabled = false,
  isRequired = false,
  variant = 'outline',
  bgColor,
  height,
  paddingLeft,
  onChange,
}) => {
  // Handle change of input
  const handleChange = (valueString: string) => {
    const numericValue = valueString ? parseFloat(valueString) : 0;
    if (onChange) onChange(numericValue);
  };

  return (
    <Box marginTop={marginTop} width="100%">
      <NumberInput
        value={value}
        step={step}
        isDisabled={isDisabled}
        onChange={handleChange}
        width="100%"
        variant={variant}
      >
        <NumberInputField
          placeholder={placeholder}
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={color}
          textAlign={textAlign}
          marginStart={marginStart}
          padding={padding}
          required={isRequired}
          bgColor={bgColor}
          height={height}
          paddingLeft={paddingLeft}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Box>
  );
};
