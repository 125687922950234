import React from 'react';
import { Select, SelectProps, ResponsiveValue } from '@chakra-ui/react';

interface CustomInputRadProps extends SelectProps {
  options: string; // Comma-separated string of options
  placeholder?: string;
  fontSize?: ResponsiveValue<string>;
  fontWeight?: ResponsiveValue<string>;
  color?: string;
  textAlign?: 'left' | 'right' | 'center' | 'justify';
  marginStart?: ResponsiveValue<string>;
  isDisabled?: boolean;
  isRequired?: boolean;
  variant?: 'outline' | 'filled' | 'flushed' | 'unstyled';
  bgColor?: string;
  height?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  marginTop?: string;
}

export const CustomInputRad: React.FC<CustomInputRadProps> = ({
  options,
  placeholder = 'Select option',
  fontSize,
  fontWeight,
  color = 'black',
  textAlign = 'left',
  marginStart,
  isDisabled = false,
  isRequired = false,
  variant = 'outline',
  bgColor,
  height,
  paddingLeft,
  marginTop,
  onChange,
}) => {
  const optionList = options.split(',').map(option => option.trim());

  return (
    <Select
      placeholder={placeholder}
      isDisabled={isDisabled}
      isRequired={isRequired}
      variant={variant}
      onChange={onChange}
      width="100%"
      bgColor={bgColor}
      height={height}
      paddingLeft={paddingLeft}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      textAlign={textAlign}
      marginStart={marginStart}
      marginTop={marginTop}
    >
      {optionList.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
