import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, List, ListItem, ResponsiveValue } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import elasticlunr, { Index } from 'elasticlunr';
import { useSettings } from '../../stateManagement/SettingsContext';
import { InputWithIcon } from '../molecules/InputWithIcon';

// Define the structure of articles
interface Article {
  section_name: string;
  id: string;
  title: string;
  body: string;
  stags: string[];
  publish_id: string;
}

// Props for the SearchBar component
export interface SearchBarProps {
  searchBarBgColor?: string;
  searchBarTextColor?: string;
  placeholderText?: string;
  placeholderColor?: string;
  placeholderFontSize?: ResponsiveValue<string>;
  placeholderFontFamily?: string;
  searchIconSize?: string;
  iconColor?: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  searchBarBgColor = '#ffffff',
  searchBarTextColor,
  searchIconSize = '100%',
  placeholderFontSize,
  placeholderFontFamily,
  iconColor,
  placeholderText,
  placeholderColor,
}) => {
  const { faqs, language, appId } = useSettings(); // Assume useSettings provides `faqs` and `language`
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<Article[]>([]);
  const navigate = useNavigate();

  // Extract plain text from HTML strings
  const extractTextFromHTML = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return (doc.body.textContent || '').toLowerCase().trim();
  };

  // Filter articles based on app and language
  const filteredArticles = useMemo(
    () =>
      faqs?.filter(
        (article: any) =>
          article.translations[language] &&
          article.app_id === appId &&
          article['published?'] === true,
      ) || [],
    [faqs, language, appId],
  );

  // Normalize articles for indexing
  const normalizedArticles: Article[] = useMemo(() => {
    return filteredArticles.map((article: any) => ({
      id: article.id,
      title: article.translations[language]?.title.toLowerCase() || '',
      body: extractTextFromHTML(article.translations[language]?.body || ''),
      stags: article.translations[language]?.stags || [],
      publish_id: article.publish_id,
      section_name: article.section_translations?.[language]?.title || '',
    }));
  }, [filteredArticles, language]);

  // Initialize Elasticlunr.js index
  const createIndex = (articles: Article[]): Index<Article> => {
    const idx = new elasticlunr.Index<Article>();

    // Manually add fields without relying on `this`
    idx.addField('title');
    idx.addField('body');
    idx.addField('stags');
    idx.setRef('id');

    // Add each article to the index
    articles.forEach(article => {
      idx.addDoc(article);
    });

    return idx;
  };

  const index = useMemo(() => createIndex(normalizedArticles), [normalizedArticles]);

  // Handle search input
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    setQuery(searchValue);

    if (searchValue.trim() === '') {
      setResults([]);
    } else {
      const searchResults = index.search(searchValue, {
        fields: {
          title: { boost: 3 },
          body: { boost: 2 },
          stags: { boost: 1 },
        },
        expand: true,
      });

      setResults(
        searchResults.map(
          (result: { ref: string }) =>
            normalizedArticles.find(article => article.id === result.ref)!,
        ),
      );
    }
  };

  // Handle result click
  const handleResultClick = (publish_id: string, title: string) => {
    setResults([]);
    setQuery('');
    navigate(`/article/${language}/faq/${publish_id}-${title.toLowerCase().replace(/\s+/g, '-')}`);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('#inputwithicon') && !target.closest('#searchdropdown')) {
        setResults([]);
        setQuery('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <Box overflow="visible" position="relative">
      {/* Input Field */}
      <InputWithIcon
        onChange={handleSearchChange}
        searchBarBgColor={searchBarBgColor}
        placeholder={placeholderText}
        placeholderColor={placeholderColor}
        placeholderFontSize={placeholderFontSize}
        placeholderFontFamily={placeholderFontFamily}
        InputIcon={SearchIcon}
        height={{ base: '7vh', md: '5vh', lg: '9vh' }}
        iconSize={searchIconSize}
        iconColor={iconColor}
        value={query}
        searchBarTextColor={searchBarTextColor}
      />

      {/* Dropdown List */}
      {results.length > 0 && (
        <Box boxShadow="lg" id="searchdropdown" position="absolute" width="100%" zIndex="999">
          <List styleType="none" border="2px solid #f1f2f4" boxShadow="2xl">
            {results.slice(0, 6).map(article => {
              const ogFaq = faqs?.find(faq => faq.id === article.id); // Fetch the original article
              return (
                <ListItem key={article.id}>
                  <Box
                    as="button"
                    onClick={() => handleResultClick(article.publish_id, article.title)}
                    textAlign="left"
                    display="block"
                    width="100%"
                    padding="8px 12px"
                    bg={searchBarBgColor}
                    color={searchBarTextColor}
                    _hover={{
                      transform: 'scale(1.01)',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                    borderRadius="sm"
                    borderBottom="2px solid #f1f2f4"
                  >
                    <strong>{ogFaq?.translations[language].title}</strong>
                    {/* Section name only for visual context */}
                    <Box fontSize="sm" color="gray.500">
                      {article.section_name}
                    </Box>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </Box>
  );
};
