import { Text, Heading, HeadingProps, TextProps, ResponsiveValue } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface CustomTextProps extends TextProps, HeadingProps {
  variant?: 'body' | 'h1' | 'h2' | 'h3';
  children: ReactNode;
  fontSize?: ResponsiveValue<string>;
  fontWeight?: string;
  color?: string;
  textAlign?: 'left' | 'right' | 'center' | 'justify';
  marginStart?: ResponsiveValue<string>;
  width?: ResponsiveValue<string>;
  overflow?: string;
}

export const CustomText: React.FC<CustomTextProps> = ({
  variant = 'body',
  children,
  fontSize,
  fontWeight,
  color,
  textAlign,
  marginStart,
  padding,
  width,
  overflow,
  marginBottom,
}) => {
  const getFontSize = () => {
    switch (variant) {
      case 'h1':
        return '2xl';
      case 'h2':
        return 'xl';
      case 'h3':
        return 'lg';
      default:
        return 'md';
    }
  };

  const getFontWeight = () => {
    switch (variant) {
      case 'h1':
        return 'bold';
      case 'h2':
        return 'semibold';
      default:
        return 'normal';
    }
  };

  const commonProps = {
    fontSize: fontSize || getFontSize(),
    fontWeight: fontWeight || getFontWeight(),
    color,
    textAlign: textAlign || 'left',
    marginStart,
    padding,
    marginBottom,
    width,
    overflow,
  };

  if (typeof children === 'string' && children.includes('<br />')) {
    return (
      <Text
        fontSize={commonProps.fontSize}
        fontWeight={commonProps.fontWeight}
        color={commonProps.color}
        textAlign={commonProps.textAlign}
        marginStart={commonProps.marginStart}
        padding={commonProps.padding}
        marginBottom={commonProps.marginBottom}
        width={commonProps.width}
        overflow={commonProps.overflow}
        dangerouslySetInnerHTML={{ __html: children as string }} // Render HTML with <br />
      />
    );
  }
  switch (variant) {
    case 'h1':
      return (
        <Heading
          as="h1"
          fontSize={commonProps.fontSize}
          fontWeight={commonProps.fontWeight}
          color={commonProps.color}
          textAlign={commonProps.textAlign}
          marginStart={commonProps.marginStart}
          padding={commonProps.padding}
          marginBottom={commonProps.marginBottom}
          width={commonProps.width}
          overflow={commonProps.overflow}
        >
          {children}
        </Heading>
      );
    case 'h2':
      return (
        <Heading
          as="h2"
          fontSize={commonProps.fontSize}
          fontWeight={commonProps.fontWeight}
          color={commonProps.color}
          textAlign={commonProps.textAlign}
          marginStart={commonProps.marginStart}
          padding={commonProps.padding}
          marginBottom={commonProps.marginBottom}
          width={commonProps.width}
          overflow={commonProps.overflow}
        >
          {children}
        </Heading>
      );
    case 'h3':
      return (
        <Heading
          as="h3"
          fontSize={commonProps.fontSize}
          fontWeight={commonProps.fontWeight}
          color={commonProps.color}
          textAlign={commonProps.textAlign}
          marginStart={commonProps.marginStart}
          padding={commonProps.padding}
          marginBottom={commonProps.marginBottom}
          width={commonProps.width}
          overflow={commonProps.overflow}
        >
          {children}
        </Heading>
      );
    default:
      return (
        <Text
          fontSize={commonProps.fontSize}
          fontWeight={commonProps.fontWeight}
          color={commonProps.color}
          textAlign={commonProps.textAlign}
          marginStart={commonProps.marginStart}
          mb={commonProps.marginBottom}
          padding={commonProps.padding}
          width={commonProps.width}
          overflow={commonProps.overflow}
        >
          {children}
        </Text>
      );
  }
};
