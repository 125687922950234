import React, { ChangeEventHandler } from 'react';
import { FormControl, ResponsiveValue } from '@chakra-ui/react';
import { Label } from '../atoms/Label';
import { Description } from '../atoms/Description';
import { CustomInput } from '../atoms/CustomInput';

interface CustomInputEmailProps {
  question: string;
  placeholder?: string;
  required: boolean;
  description?: string;
  marginStart?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  marginTop?: string;
  fontSize?: string;
  descriptionFontSize?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
}

export const CustomInputEmail: React.FC<CustomInputEmailProps> = ({
  question,
  placeholder,
  required,
  description,
  marginStart,
  paddingLeft,
  marginTop,
  fontSize,
  descriptionFontSize,
  onChange,
  value,
}) => {
  const inputId = 'email-field';

  return (
    <div>
      <FormControl marginStart={marginStart} paddingLeft={paddingLeft} marginTop={marginTop}>
        <Label label={question} htmlFor={inputId} required={required} fontSize={fontSize} />
        <Description description={description} fontSize={descriptionFontSize} />
        <CustomInput
          isRequired={required}
          placeholder={placeholder}
          onChange={onChange}
          size="md"
          value={value}
          type="email"
          isDisabled={false}
          // isInvalid={!email.includes('@')}
          variant="outline"
          bgColor="#FFFFFF"
          height="40px"
          paddingLeft="10px"
          fontSize="md"
          marginTop={marginTop}
        />
      </FormControl>
    </div>
  );
};
