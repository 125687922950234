import { Button, HStack, VStack } from '@chakra-ui/react';
import { CustomText } from '../atoms';

export const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) => (
  <VStack mt={4}>
    <HStack spacing={4}>
      <Button onClick={() => onPageChange(currentPage - 1)} isDisabled={currentPage === 1}>
        Previous
      </Button>
      <CustomText>
        Page {currentPage} of {totalPages}
      </CustomText>
      <Button onClick={() => onPageChange(currentPage + 1)} isDisabled={currentPage === totalPages}>
        Next
      </Button>
    </HStack>
  </VStack>
);
