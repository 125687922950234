// LandingPage.tsx
import React from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MenuCardList } from '../organisms/MenuCardList';
import { NewsSection } from '../organisms/NewsSection';
import { PopularArticles } from '../organisms/PopularArticles';
import { BannerWithSearchBar } from '../organisms/BannerWithSearchBar';
import { ContactBtnSection } from '../molecules/ContactUsBtnSection';
import { useSettings } from '../../stateManagement/SettingsContext';
import { Layout } from '../templates';
import { SectionGrid } from '../organisms/SectionGrid';

export const LandingPage: React.FC = () => {
  const { settings, sectionsData, faqs, language } = useSettings(); // Destructure from context
  const navigate = useNavigate();
  // Dummy image and functions for missing variables

  const onClickContactUs = () => {
    // eslint-disable-next-line
    console.log('Contact Us button clicked');
    navigate('/contactUs');
  };

  const onClickMenuCard = (menuCardLink: string) => {
    window.open(menuCardLink, '_self');
  };

  const onClickPopularArticle = (publish_id: string | number) => {
    const faq = faqs?.find(item => item.publish_id === publish_id);
    if (faq) {
      const translation = faq.translations[language];
      if (translation) {
        const formattedTitle = translation.title.toLowerCase().replace(/\s+/g, '-');
        navigate(`/article/${language}/faq/${publish_id}-${formattedTitle}`);
      }
    }
  };

  return (
    <Layout>
      <Box>
        {/* Banner with Search Bar */}
        {settings?.configs.showBanner && (
          <BannerWithSearchBar
            imageSrc={settings?.configs.bannerImageUrl}
            altText="Banner image"
            bannerText="How can we help you?"
            placeholderText="Search for articles"
            placeholderFontSize={{ base: '18px', md: '20px', lg: '22px' }} // Responsive font size
            placeholderColor={settings?.configs.placeholderColor}
            searchBarTextColor={settings?.configs.searchBarTextColor}
            showOverlay={settings?.configs.showOverlay}
            showSearchBar={settings?.configs.showSearchBar}
          />
        )}

        {/* Main content with responsive padding */}
        <Box px={{ base: '2vw', md: '1vw', lg: '15vw' }}>
          {/* Menu Cards */}
          {settings?.configs.showMenuCards && (
            <MenuCardList
              items={settings?.data.menuCards}
              menuCardsBgColor={settings?.configs.secondaryBgColor ?? ''}
              menuCradsTextColor={settings?.configs.secondaryTextColor ?? ''}
              onClickMenuCard={(id: string) => onClickMenuCard(id)}
            />
          )}
          {/* News Section */}
          {settings?.configs.showNewsSection && (
            <NewsSection
              newsArticles={settings?.data.newsArticles}
              newsArticleBgColor={settings?.configs.primaryBgColor}
              newsArticleTextColor={settings?.configs.primaryTextColor}
              newsSectionBgColor={settings?.configs.secondaryBgColor}
              newsSectionHeadingTextColor={settings?.configs.secondaryTextColor}
              paginationSelectedPageBgColor={settings?.configs.paginationSelectedPageBgColor}
              paginationSelectedPageTextColor={settings?.configs.paginationSelectedPageTextColor}
            />
          )}

          {/* Popular Articles */}
          {settings?.configs.showPopularArticles && (
            <PopularArticles
              faqArticles={settings?.data.popularArticles}
              bgColor={settings?.configs.primaryBgColor}
              faqArticlesTextColor={settings?.configs.secondaryTextColor ?? ''}
              faqArticlesBgColor={settings?.configs.secondaryBgColor ?? ''}
              primaryTextColor={settings?.configs.primaryTextColor ?? ''}
              onClickPopularArticle={onClickPopularArticle}
            />
          )}

          {/* section wise faq display */}
          <SectionGrid
            textColor={settings?.configs.secondaryTextColor}
            sectionBgColor={settings?.configs.secondaryBgColor}
            sections={sectionsData}
            sectionIcons={settings?.data.sectionIcons}
          />
        </Box>

        {/* Contact Button Section */}
        {settings?.configs.showContactUsButton && (
          <ContactBtnSection
            onButtonClick={onClickContactUs}
            variant="h1"
            color={settings?.configs.contactUsButtonTextColor}
            buttonBgColor={settings?.configs.contactUsButtonBgColor}
            needHelpText={settings?.configs.needHelptext}
            needHelpTextColor={settings?.configs.needHelpTextColor}
            mt="4vh"
          />
        )}
      </Box>
    </Layout>
  );
};
