import { useEffect } from 'react';
import { Card, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
// import { HeaderText, PrimaryButton, CardImage } from '../atoms';
import { TextFieldWithLabel } from '../molecules'; // The molecule created earlier
import { useAuth } from '../../stateManagement/auth/useAuth';
import { CustomText } from '../atoms/CustomText';
import { CustomImage } from '../atoms/CustomImage';
import { CustomButton } from '../atoms/CustomButton';

interface LoginFormInputs {
  username: string;
  password: string;
}

export const Login = () => {
  const { handleLogin, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const methods = useForm<LoginFormInputs>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const { handleSubmit } = methods;

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/'); // Redirect to your desired route
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = (data: LoginFormInputs) => {
    handleLogin(data.username, data.password);
  };

  return (
    <Card padding="20px" boxShadow="md" maxW="md" margin="0 auto">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} align="center">
            <CustomImage src="/logo.png" alt="Logo" />
            <CustomText>Login</CustomText>

            <Stack spacing={3}>
              {/* Username Field */}
              <TextFieldWithLabel
                name="username"
                label="Username"
                placeholder="Enter your username"
                rules={{ required: 'Username is required' }}
              />

              {/* Password Field */}
              <TextFieldWithLabel
                name="password"
                label="Password"
                placeholder="Enter your password"
                rules={{ required: 'Password is required' }}
              />

              {/* Submit Button */}
              <CustomButton type="submit"> Login</CustomButton>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Card>
  );
};
