// TextFieldMolecule.tsx
import React from 'react';
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { HSFormLabel, CustomInput } from '../atoms';

interface TextFieldMoleculeProps {
  /**
   * The name of the form field, which is used for validation and state management.
   */
  name: string;

  /**
   * The label displayed for the text field.
   */
  label: string;

  /**
   * Optional placeholder text displayed inside the text field when it is empty.
   */
  placeholder?: string;

  /**
   * Validation rules to be applied to the text field from react-hook-form.
   *
   * This can include required, minLength, maxLength, etc.
   */
  rules?: RegisterOptions; // Validation rules from react-hook-form
}

/**
 * A text field component that integrates with react-hook-form for form handling.
 *
 * This component uses `Controller` from react-hook-form to manage the input state
 * and validation. It provides a labeled text field with error handling and styling.
 *
 * @component
 * @example
 * <TextFieldWithLabel
 *   name="username"
 *   label="Username"
 *   placeholder="Enter your username"
 *   rules={{ required: "Username is required" }}
 * />
 *
 * @param {TextFieldMoleculeProps} props - The props for the text field component, including `name`, `label`, `placeholder`, and `rules`.
 * @returns {JSX.Element} A styled text field with a label and error handling.
 */
export const TextFieldWithLabel: React.FC<TextFieldMoleculeProps> = ({
  name,
  label,
  placeholder,
  rules,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl isInvalid={!!errors[name]}>
      <HSFormLabel htmlFor={name}>{label}</HSFormLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <CustomInput
            value={field.value || ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            placeholder={placeholder}
          />
        )}
      />
      <FormErrorMessage>{errors[name]?.message as React.ReactNode}</FormErrorMessage>
    </FormControl>
  );
};
