import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, LoginPayload } from './authTypes';

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginPayload>) => {
      const { username, password } = action.payload;
      // Mock authentication: Just check if username and password are provided
      if (username && password) {
        state.isAuthenticated = true;
        state.user = username; // Store only username for demo purposes
      }
    },
    logout: state => {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
