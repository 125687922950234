import { Switch, FormControl, FormLabel } from '@chakra-ui/react';

interface ToggleAtomProps {
  label: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export const ToggleAtom: React.FC<ToggleAtomProps> = ({ label, value, onChange }) => (
  <FormControl display="flex" alignItems="center">
    <FormLabel>{label}</FormLabel>
    <Switch
      isChecked={value}
      onChange={e => {
        // console.log('Toggle changed:', e.target.checked); // Debug log
        onChange(e.target.checked);
      }}
    />
  </FormControl>
);
