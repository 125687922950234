import React from 'react';
import { Tr, Td, Badge, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

interface CustomTableRowProps<T> {
  rowBorderColor?: string;
  fields: string[];
  item: T;
  fieldsAsLinks?: string[];
}

export const CustomTableRow = <T,>({
  rowBorderColor,
  fields,
  item,
  fieldsAsLinks = [],
}: CustomTableRowProps<T>) => {
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate('/tickethistory');
  };

  return (
    <Tr
      borderBottom={`2px solid ${rowBorderColor}`}
      _hover={{ bg: 'gray.200', cursor: 'pointer' }}
      onClick={handleRowClick}
    >
      {fields.map(field => {
        const value = item[field as keyof T];

        // Status column styling
        if (field === 'Status' && typeof value === 'string') {
          return (
            <Td key={field} padding="0.5rem" height="2rem" textAlign="center">
              <Badge
                bg="#dedede"
                color="gray"
                px="3"
                py="1.5"
                borderRadius="full"
                textTransform="none"
                fontWeight="medium"
                fontSize="sm"
              >
                {value}
              </Badge>
            </Td>
          );
        }
        if (field === 'Ticket #' && typeof value === 'number') {
          return (
            <Td key={field} padding="0.8rem" height="2rem">
              {fieldsAsLinks.includes(field) ? (
                <Button
                  variant="link"
                  color="#3D80CC"
                  fontWeight="semibold"
                  padding="0"
                  minWidth="auto"
                >
                  {value}
                </Button>
              ) : (
                <Text fontWeight="semibold" color="#3D80CC">
                  {value}
                </Text>
              )}
            </Td>
          );
        }

        // Default column styling
        return (
          <Td key={field} padding="0.8rem" height="2rem">
            {fieldsAsLinks.includes(field) ? (
              <Button
                onClick={() => navigate(`/tickethistory`)} // Link to Ticket History
                variant="link"
                color="#3D80CC"
                fontWeight="semibold"
                padding="0"
                minWidth="auto"
              >
                {String(value ?? '')}
              </Button>
            ) : (
              String(value ?? '')
            )}
          </Td>
        );
      })}
    </Tr>
  );
};
