import React, { useState, useEffect } from 'react';
import { Input, FormLabel, Box, HStack, Text } from '@chakra-ui/react';

interface ColorPickerAtomProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  width?: string;
  labelColor?: string;
}

export const ColorPickerAtom: React.FC<ColorPickerAtomProps> = ({
  label,
  value,
  onChange,
  width = '100%',
  labelColor = 'black',
}) => {
  const [hexValue, setHexValue] = useState(value);

  // Sync hexValue with prop value when the component mounts or updates
  useEffect(() => {
    setHexValue(value);
  }, [value]);

  // Handle color change from the color picker
  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setHexValue(newColor);
    onChange(newColor); // Pass the new color to the parent
  };

  // Handle manual hex input change
  const handleHexInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHex = e.target.value;
    setHexValue(newHex);

    // Validate hex code (basic validation: #FFF or #FFFFFF)
    if (/^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(newHex)) {
      onChange(newHex); // Update the parent with the valid hex code
    }
  };

  return (
    <Box mb={4} width={width}>
      <FormLabel color={labelColor}>{label}</FormLabel>
      <HStack spacing={4} align="center">
        {/* Color input (color picker) */}
        <Input type="color" value={hexValue} onChange={handleColorChange} width="80px" />
        {/* Manual hex input */}
        <Input
          value={hexValue}
          onChange={handleHexInputChange}
          placeholder="#FFFFFF"
          size="md"
          width="120px"
        />
        <Text fontSize="sm" color="gray.600">
          Selected Color: {hexValue}
        </Text>
      </HStack>
    </Box>
  );
};
