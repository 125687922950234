import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { CustomHeading } from '../atoms/CustomHeading';
import { useSettings } from '../../stateManagement/SettingsContext';
import { InputWithIcon } from '../molecules/InputWithIcon';
import { CustomTable } from '../molecules/CustomTable';
import { HelpshiftWidget } from '../atoms/HelpshiftWidget';

export interface PlayerHubProps {
  PlayerHubTableFields: PlayerHubTableField[];
}

export interface PlayerHubTableField {
  ticketNumber: number;
  ticketSummary: string;
  status: string;
  date: string;
  platform: string;
}

export const PlayerHub: React.FC<PlayerHubProps> = ({ PlayerHubTableFields }) => {
  const { settings } = useSettings();
  const [inputValue, setInputValue] = useState<string>('');

  // Handle change event for input
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value); // Update input value
  };

  // Filter data based on input value (case-insensitive match)
  const filteredTableData = PlayerHubTableFields.filter(field =>
    field.ticketSummary.toLowerCase().includes(inputValue.toLowerCase()),
  ).map(field => ({
    'Ticket #': field.ticketNumber,
    'Ticket Summary': field.ticketSummary,
    Status: field.status,
    Date: field.date,
    Platform: field.platform,
  }));

  return (
    <Box padding="2rem" maxWidth="1400px" margin="0 auto">
      <HelpshiftWidget
        PLATFORM_ID="ravi-demo_platform_20220331005029753-d9ae2b34048d999"
        DOMAIN="ravi-demo"
      />
      <Box marginBottom="2rem">
        <CustomHeading
          question="MY TICKETS"
          fontSize="4xl"
          descriptionFontSize="md"
          fontWeight="400"
          marginTop="1rem"
        />
      </Box>
      <InputWithIcon
        searchBarTextColor="#000000"
        searchBarBgColor={settings?.configs.searchBarBgColor}
        placeholderColor="gray"
        placeholder="Search Fields"
        placeholderFontSize={{ base: '18px', md: '20px', lg: '22px' }}
        InputIcon={SearchIcon}
        height={{ base: '5vh', md: '5vh', lg: '7vh', sm: '15vh' }}
        iconSize="4vh"
        onChange={handleChange}
        value={inputValue}
        width="100%"
        px="0"
      />
      <CustomTable
        height="50vh"
        mt={{ base: '5vh' }}
        fields={['Ticket #', 'Ticket Summary', 'Status', 'Date', 'Platform']}
        fieldsWithIcons={['Ticket #', 'Status', 'Date', 'Platform']}
        fieldsAsLinks={['Ticket #']}
        centerAlignFields={['Status']}
        rowBorderColor="#dedede"
        data={filteredTableData}
      />
    </Box>
  );
};
