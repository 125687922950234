import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, List, ListItem } from '@chakra-ui/react';
import { useSettings, SectionData } from '../../stateManagement/SettingsContext';

interface RelatedSectionsProps {
  sectionsData: SectionData[] | null;
  bgColor?: string;
  textColor?: string;
}

export const RelatedSections: React.FC<RelatedSectionsProps> = ({
  sectionsData,
  bgColor,
  textColor,
}) => {
  const { language } = useSettings();
  const navigate = useNavigate();

  const getRelatedSections = () => {
    return sectionsData?.map(item => ({
      section_name: item.section_name,
      section_id: item.section_id,
    }));
  };

  const onClickRelatedSections = (section_name: string) => {
    navigate(`/sections/${language}/section/${section_name.toLowerCase().replace(/\s+/g, '-')}`);
  };

  const relatedSections = getRelatedSections();

  return (
    <Box backgroundColor={bgColor} textColor={textColor} p={2}>
      <List styleType="none">
        {relatedSections?.map(section => (
          <ListItem key={section.section_name}>
            <Box
              as="button"
              onClick={() => onClickRelatedSections(section.section_name)}
              textAlign="left"
              display="block"
              width="100%"
              padding="8px 12px"
              _hover={{
                backgroundColor: 'gray.100',
              }}
              borderBottom="1px solid #f1f2f4"
            >
              <strong>{section.section_name || 'No title available'}</strong>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
