import React from 'react';
import { Box, Text, Link, Stack, Divider, useColorModeValue } from '@chakra-ui/react';
import { CustomImage } from '../atoms';

interface FooterProps {
  footerBgColor: string;
  footerTextColor: string;
  fontSize?: string;
  footerNote?: string;
  links?: FooterLink[];
  imageSrc?: string;
  logoWidth?: { base: string; lg: string };
  logoPadding?: string;
  showFooterLogo: boolean;
  showFooterLinks: boolean;
  gameTitle?: string;
}
export interface FooterLink {
  text: string;
  href: string;
  id: string | number;
}

export const Footer: React.FC<FooterProps> = ({
  footerBgColor = 'gray.900',
  footerTextColor = 'gray.500',
  fontSize = 'sm',
  footerNote = '© 2024 Netflix',
  imageSrc,
  links = [],
  logoWidth = { base: '50%', lg: '15%' },
  logoPadding = '4',
  showFooterLogo = true,
  showFooterLinks = true,
  gameTitle,
}) => {
  return (
    <Box bg={footerBgColor} color={footerTextColor} py="6" textAlign="center">
      {/* Logo Section */}
      {showFooterLogo && imageSrc && (
        <CustomImage src={imageSrc} width={logoWidth} margin="auto" padding={logoPadding} />
      )}

      {/* Footer Note */}
      <Text color={footerTextColor} fontSize={fontSize} mt="2">
        {gameTitle}
      </Text>
      <Text mt="1" color={footerTextColor} fontSize={fontSize}>
        {footerNote}
      </Text>

      {/* Divider */}
      <Divider my="4" borderColor={useColorModeValue('gray.600', 'gray.300')} />

      {/* Links Section */}
      {showFooterLinks && links.length > 0 && (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing="4"
          justify="center"
          align="center"
          wrap="wrap"
        >
          {links.map(link => (
            <Link
              key={link.id}
              href={link.href}
              color={footerTextColor}
              fontSize={fontSize}
              _hover={{ color: useColorModeValue('gray.200', 'gray.600') }}
            >
              {link.text}
            </Link>
          ))}
        </Stack>
      )}
    </Box>
  );
};
