import React from 'react';
import { FormLabel } from '@chakra-ui/react';

interface LabelAtomProps {
  /**
   * The `htmlFor` attribute that associates the label with the corresponding form control.
   */
  htmlFor: string;

  /**
   * The content to be displayed inside the label.
   */
  children: React.ReactNode;
}

/**
 * A form label component that enhances accessibility and styling.
 *
 * This component wraps the Chakra UI `FormLabel` and provides a styled label
 * for form controls. It associates the label with the corresponding input
 * element using the `htmlFor` attribute.
 *
 * @component
 * @example
 * <HSFormLabel htmlFor="username">Username</HSFormLabel>
 *
 * @param {LabelAtomProps} props - The props for the label component, including `htmlFor` and `children`.
 * @returns {JSX.Element} A styled label element for form controls.
 */
export const HSFormLabel: React.FC<LabelAtomProps> = ({ htmlFor, children }) => {
  return (
    <FormLabel htmlFor={htmlFor} color="gray.700">
      {children}
    </FormLabel>
  );
};
