// src/store.ts
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Uses localStorage for web

import { authReducer } from './auth/authSlice';
import { issuesReducer } from './issues/issuesSlice';

// Define the persist configuration
const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Only persist the 'auth' reducer
};

// Combine your reducers
const rootReducer = combineReducers({
  auth: authReducer,
  issues: issuesReducer,
  // Add other reducers here if needed
});
export type RootState = ReturnType<typeof rootReducer>;

// Create a persisted reducer
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

// Configure your store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  // You can add middleware and other configurations here if needed
});

// Create a persistor
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store

export type AppDispatch = typeof store.dispatch;
