// AdminPage.tsx
import React, { useState } from 'react';
import { Box, Button, Spinner, Stack, useToast, Flex } from '@chakra-ui/react';
import { useSettings } from '../../stateManagement/SettingsContext';
import { ColorSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/ColorSettingsMolecule';
import { HeaderSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/HeaderSettingsMolecule';
import { MenuCardSettingsOrganism } from '../molecules/AdminPageSettingsMolecules/MenuCardSettingsMolecule';
import { NewsSectionSettings } from '../molecules/AdminPageSettingsMolecules/NewsSectionSettingsMolecule';
import { FooterSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/FooterSettingsMolecule';
import { PopularArticlesSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/PopularArticlesSettingsMolecule';
import { LandingPagePreview } from './LandingPagePreview';
import { BannerSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/BannerSettingsMolecule';
import { SectionsSettingsMolecule } from '../molecules/AdminPageSettingsMolecules/SectionsSettingsMolecule';

export const AdminPage: React.FC = () => {
  const { settings, setSettings, saveSettings, sectionsData } = useSettings();
  const toast = useToast();
  const [localSettings, setLocalSettings] = useState(settings);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    if (!localSettings) return;
    setIsSaving(true);
    try {
      setSettings(localSettings);
      await saveSettings(localSettings);
      toast({
        title: 'Settings saved successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch {
      toast({
        title: 'Error saving settings',
        description: 'Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleConfigChange = (key: string, value: any) => {
    setLocalSettings(prev => ({
      ...prev!,
      configs: { ...prev!.configs, [key]: value },
    }));
  };

  const handleMenuCardChange = (updatedMenuCards: any) => {
    setLocalSettings(prev => ({
      ...prev!,
      data: { ...prev!.data, menuCards: updatedMenuCards },
    }));
  };

  const handleNewsArticleChange = (updatedNewsArticles: any) => {
    setLocalSettings(prev => ({
      ...prev!,
      data: { ...prev!.data, newsArticles: updatedNewsArticles },
    }));
  };

  const handlePopularFaqArticleChange = (updatedPopularFaqArticles: any) => {
    setLocalSettings(prev => ({
      ...prev!,
      data: { ...prev!.data, popularArticles: updatedPopularFaqArticles },
    }));
  };

  const handleSectionIconsChange = (updatedSectionIcons: any) => {
    setLocalSettings(prev => ({
      ...prev!,
      data: { ...prev!.data, sectionIcons: updatedSectionIcons },
    }));
  };

  if (!localSettings) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Flex direction={['column', 'row']} gap={6}>
        <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm" width={['100%', '30%']}>
          <Stack spacing={6}>
            <ColorSettingsMolecule
              configs={localSettings.configs}
              onConfigChange={handleConfigChange}
            />
            <HeaderSettingsMolecule
              configs={localSettings.configs}
              onConfigChange={handleConfigChange}
            />
            <BannerSettingsMolecule
              configs={localSettings.configs}
              onConfigChange={handleConfigChange}
            />
            <MenuCardSettingsOrganism
              menuCards={localSettings.data.menuCards}
              onMenuCardChange={handleMenuCardChange}
            />
            <NewsSectionSettings
              newsCards={localSettings.data.newsArticles}
              onNewsArticleChange={handleNewsArticleChange}
            />
            <PopularArticlesSettingsMolecule
              sectionsData={sectionsData}
              onSelectionChange={handlePopularFaqArticleChange}
            />

            <SectionsSettingsMolecule
              sectionIcons={localSettings.data.sectionIcons}
              onSectionIconChange={handleSectionIconsChange}
            />

            <FooterSettingsMolecule
              configs={localSettings.configs}
              onFooterSettingsChange={handleConfigChange}
            />
          </Stack>
        </Box>

        <Box width={['100%', '70%']}>
          <Button onClick={handleSave} isLoading={isSaving} colorScheme="blue" size="sm">
            Save Settings
          </Button>
          <Box
            bg="gray.100"
            p={4}
            borderRadius="md"
            boxShadow="sm"
            width="100%"
            overflowY="scroll"
            height="100vh"
          >
            <LandingPagePreview settings={localSettings} sectionsData={sectionsData} />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
