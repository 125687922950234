// ArticleFeedback.tsx
import { HStack } from '@chakra-ui/react';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { CustomButton, CustomText } from '../../atoms';

export const ArticleFeedback: React.FC = () => {
  return (
    <HStack spacing={6} borderTop="1px solid gray" pt="4" mt="10">
      <CustomText variant="h3" fontWeight="bold">
        Was This Article Helpful?
      </CustomText>
      <CustomButton
        onClick={() => {
          // console.log('Thumbs up clicked');
        }}
        colorScheme="green"
        icon={<FaThumbsUp />}
        variant="outline"
      />
      <CustomButton
        onClick={() => {
          // console.log('Thumbs down clicked');
        }}
        colorScheme="red"
        icon={<FaThumbsDown />}
        variant="outline"
      />
    </HStack>
  );
};
