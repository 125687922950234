import React, { useEffect } from 'react';

declare global {
  interface Window {
    helpshiftConfig?: {
      platformId: string;
      domain: string;
    };
    Helpshift?: (...args: any[]) => void;
  }
}

interface HelpshiftWidgetProps {
  PLATFORM_ID: string;
  DOMAIN: string;
  openWidget?: boolean;
}

export const HelpshiftWidget: React.FC<HelpshiftWidgetProps> = ({
  PLATFORM_ID,
  DOMAIN,
  openWidget = false,
}) => {
  useEffect(() => {
    const setHelpshiftConfig = () => {
      window.helpshiftConfig = {
        platformId: PLATFORM_ID,
        domain: DOMAIN,
      };
    };
    setHelpshiftConfig();

    // Helpshift integration script
    const loadHelpshiftScript = (document: Document, scriptId: string) => {
      if (typeof window.Helpshift !== 'function') {
        // eslint-disable-next-line
        const Helpshift: any = function (...args: any[]) {
          Helpshift.q.push(args);
        };
        Helpshift.q = [];
        window.Helpshift = Helpshift;

        const firstScript = document.getElementsByTagName('script')[0];
        if (document.getElementById(scriptId)) return;

        const script = document.createElement('script');
        script.async = true;
        script.id = scriptId;
        script.src = 'https://webchat.helpshift.com/webChat.js';

        script.addEventListener('load', () => {
          window.Helpshift?.('init'); // Safely invoke the init method if Helpshift is defined
        });

        setTimeout(() => {
          const el = document.getElementById('hs-web-sdk-iframe-launcher');
          if (el) {
            el.style.top = '80%';
          }
        }, 500);

        if (openWidget) {
          Helpshift('open');
        }

        firstScript.parentNode?.insertBefore(script, firstScript);
      }
    };

    loadHelpshiftScript(document, 'hs-chat');
  }, [PLATFORM_ID, DOMAIN]);

  return null; // This component does not render anything
};
