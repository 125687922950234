// src/components/atoms/MenuCard.tsx
import { Box, Text, Icon } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';
import { FaRegQuestionCircle } from 'react-icons/fa';
import * as FaIcons from 'react-icons/fa';

export interface MenuCardItem {
  id: string; // Add id to the props
  icon: string; // The type of the icon component, e.g., from react-icons
  bg?: string;
  textColor?: string;
  title: string;
  onClickMenuCard: (id: string) => void;
  menuCardLink: string;
}

export const getIconFromString = (iconString: string): IconType | null => {
  const iconName = iconString as keyof typeof FaIcons; // Assert that iconName is a valid key of FaIcons
  const iconComponent = FaIcons[iconName]; // Access the corresponding icon from FaIcons

  if (iconComponent) {
    return iconComponent; // Return the icon component
  }
  return null;
};

export const MenuCard: React.FC<MenuCardItem> = ({
  id, // Destructure the id prop
  icon,
  bg,
  textColor,
  title,
  onClickMenuCard,
  menuCardLink,
}) => {
  return (
    <Box
      id={id}
      as="button"
      bg={bg}
      textColor={textColor}
      borderRadius="md"
      boxShadow="md"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      _hover={{ boxShadow: 'lg', transform: 'scale(1.05)' }}
      onClick={() => onClickMenuCard(menuCardLink)}
    >
      <Icon
        as={getIconFromString(icon) || FaRegQuestionCircle} // Provide fallback icon
        boxSize={{ base: 6, md: 8, lg: 10 }} // Responsive icon size
        mb={2}
        color={textColor}
      />
      <Text
        fontSize={{ base: 'xs', md: 'sm', lg: 'md' }} // Responsive font size
        fontWeight="bold"
        color={textColor}
        textAlign="center"
      >
        {title}
      </Text>
    </Box>
  );
};
