import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch, persistor } from '../store';
import { login, logout } from './authSlice';

export const useAuth = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);
  const handleLogin = (username: string, password: string) => {
    dispatch(login({ username, password }));
  };

  const handleLogout = () => {
    dispatch(logout());
    persistor.purge();
  };

  return {
    isAuthenticated,
    user,
    handleLogin,
    handleLogout,
  };
};
