import React, { useState } from 'react';
import { FormControl, InputGroup, ResponsiveValue } from '@chakra-ui/react';
import { Label } from '../atoms/Label';
import { Description } from '../atoms/Description';
import { CustomInputNum } from '../atoms/CustomInputNum';

interface CustomInputNumberProps {
  question: string;
  placeholder?: string;
  required: boolean;
  description?: string;
  value?: number;
  step?: number;
  marginStart?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  marginTop?: string;
  fontSize?: string;
  descriptionFontSize?: string;
}

export const CustomInputNumber: React.FC<CustomInputNumberProps> = ({
  question,
  placeholder,
  required,
  description,
  value,
  step = 1,
  marginStart,
  paddingLeft,
  marginTop,
  fontSize,
  descriptionFontSize,
}) => {
  const [numberValue, setNumberValue] = useState(value);
  const inputId = 'number-field'; // Unique id for the input field

  const handleNumberChange = (newValue: number) => {
    setNumberValue(Number(newValue)); // Convert to number before setting state
  };

  return (
    <div>
      <FormControl marginStart={marginStart} paddingLeft={paddingLeft} marginTop={marginTop}>
        <Label label={question} htmlFor={inputId} required={required} fontSize={fontSize} />
        <Description description={description} fontSize={descriptionFontSize} />
        <InputGroup size="md">
          <CustomInputNum
            id={inputId} // Set the id for the input field
            isRequired={required}
            placeholder={placeholder}
            onChange={handleNumberChange}
            size="md"
            value={numberValue}
            step={step}
            isDisabled={false}
            variant="outline"
            bgColor="#FFFFFF"
            height="40px"
            paddingLeft="10px"
            fontSize="md"
            marginTop={marginTop}
          />
        </InputGroup>
      </FormControl>
    </div>
  );
};
