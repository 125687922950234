import React, { useState } from 'react';
import { Box, Stack, HStack, Icon } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FaEdit, FaTrash, FaCheck, FaPlus } from 'react-icons/fa';
import { CustomButton, CustomInput, CustomText } from '../../atoms';
import { NewsArticle } from '../../organisms/NewsSection';

interface NewsSectionSettingsProps {
  newsCards: NewsArticle[];
  onNewsArticleChange: (newCards: NewsArticle[]) => void;
}

export const NewsSectionSettings: React.FC<NewsSectionSettingsProps> = ({
  newsCards,
  onNewsArticleChange,
}) => {
  const [editingCardId, setEditingCardId] = useState<string | null>(null);
  const [currentEdit, setCurrentEdit] = useState<NewsArticle | null>(null);

  // Function to handle editing of a news card
  const handleEditNewsArticle = (card: NewsArticle) => {
    setEditingCardId(card.id);
    setCurrentEdit({ ...card });
  };

  // Function to handle saving the edits
  const handleSaveEdit = () => {
    if (currentEdit && editingCardId) {
      const updatedCards = newsCards.map(card => (card.id === editingCardId ? currentEdit : card));
      onNewsArticleChange(updatedCards);
      setEditingCardId(null);
      setCurrentEdit(null);
    }
  };

  // Function to handle deleting a news card
  const handleDeleteNewsCard = (id: string) => {
    const updatedCards = newsCards.filter(card => card.id !== id);
    onNewsArticleChange(updatedCards);
  };

  // Function to update the title in the input field
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, title: event.target.value });
    }
  };

  // Function to update the image URL in the input field
  const handleImageUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, imageUrl: event.target.value });
    }
  };

  // Function to update the date in the input field
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, date: event.target.value });
    }
  };

  // Function to update the destination link in the input field
  const handleDestinationLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, destinationLink: event.target.value });
    }
  };

  // Function to add a new news card at the top of the list
  const handleAddNewsCard = () => {
    const newCard: NewsArticle = {
      id: Date.now().toString(),
      title: '',
      imageUrl: '',
      date: '',
      destinationLink: '',
    };
    onNewsArticleChange([newCard, ...newsCards]);
    setEditingCardId(newCard.id);
    setCurrentEdit(newCard);
  };

  const [isExpanded, setIsExpanded] = useState(false); // State for expanding/collapsing cards
  // Toggle collapse and expand
  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box>
      {/* Section Header */}
      <HStack
        justifyContent="space-between"
        mb={4}
        border="1px solid #dedede"
        p="2"
        borderRadius="10px"
        backgroundColor="#f1f2f4"
      >
        <CustomText variant="h1" size="md">
          News Cards
        </CustomText>
        <HStack spacing={2}>
          {/* "+" Button to add a new blank news card */}
          <CustomButton
            icon={<FaPlus />}
            aria-label="Add News Card"
            onClick={handleAddNewsCard}
            colorScheme="green"
          />
          {/* Collapse/Expand Button */}
          <Icon
            as={isExpanded ? ChevronUpIcon : ChevronDownIcon}
            boxSize={6}
            onClick={toggleExpandCollapse}
            cursor="pointer"
            aria-label={isExpanded ? 'Collapse' : 'Expand'}
          />
        </HStack>
      </HStack>

      {/* Conditional rendering of menu cards based on expand/collapse state */}
      {isExpanded && (
        <Stack spacing={4}>
          <Box mt={6}>
            {newsCards.map(card => (
              <HStack
                key={card.id}
                p={4}
                borderWidth="1px"
                borderRadius="md"
                mb={4}
                justifyContent="space-between"
                alignItems="center"
              >
                {editingCardId === card.id ? (
                  // Edit Mode: Allows user to change the card properties
                  <Stack spacing={3}>
                    <CustomInput
                      value={currentEdit?.title || ''}
                      onChange={handleTitleChange}
                      placeholder="Title"
                    />
                    <CustomInput
                      value={currentEdit?.imageUrl || ''}
                      onChange={handleImageUrlChange}
                      placeholder="Image URL"
                    />
                    <CustomInput
                      value={currentEdit?.date || ''}
                      onChange={handleDateChange}
                      placeholder="Date"
                      type="date"
                    />
                    <CustomInput
                      value={currentEdit?.destinationLink || ''}
                      onChange={handleDestinationLinkChange}
                      placeholder="Destination Link"
                    />
                  </Stack>
                ) : (
                  // Display Mode: Shows the news card details
                  <Stack spacing={3}>
                    <CustomText fontWeight="bold">{card.title}</CustomText>
                    <CustomText>{card.imageUrl}</CustomText>
                    <CustomText>{card.date}</CustomText>
                    <CustomText>{card.destinationLink}</CustomText>
                  </Stack>
                )}

                <HStack>
                  {editingCardId === card.id ? (
                    // Save button with green background for saving edits
                    <CustomButton
                      icon={<FaCheck />}
                      aria-label="Save Changes"
                      onClick={handleSaveEdit}
                      colorScheme="green" // Green background for the right tick button
                    />
                  ) : (
                    // Edit button to enter edit mode
                    <CustomButton
                      icon={<FaEdit />}
                      aria-label="Edit News Card"
                      onClick={() => handleEditNewsArticle(card)}
                      colorScheme="yellow"
                    />
                  )}
                  {/* Delete button to remove the news card */}
                  <CustomButton
                    icon={<FaTrash />}
                    aria-label="Delete News Card"
                    onClick={() => handleDeleteNewsCard(card.id)}
                    colorScheme="red"
                  />
                </HStack>
              </HStack>
            ))}
          </Box>
        </Stack>
      )}
    </Box>
  );
};
