import React from 'react';
import { Text, Box } from '@chakra-ui/react';

interface LabelProps {
  label: string;
  htmlFor: string;
  required?: boolean;
  fontSize?: string;
}

export const Label: React.FC<LabelProps> = ({ label, htmlFor, required, fontSize }) => {
  return (
    <Box
      as="label"
      htmlFor={htmlFor}
      fontSize={fontSize || '16px'}
      display="flex"
      alignItems="center"
      fontWeight="bold"
    >
      {label}
      {required && (
        <Text as="span" color="red.500" marginLeft="4px">
          *
        </Text>
      )}
    </Box>
  );
};
