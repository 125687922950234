import React, { useState } from 'react';
import { Box, Icon, Stack, HStack, Button } from '@chakra-ui/react';
import { FaEdit, FaCheck, FaRegQuestionCircle } from 'react-icons/fa';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { IconPicker } from './iconPicker';
import { getIconFromString } from '../MenuCard';
import { CustomButton, CustomText } from '../../atoms';
import { SectionIcons } from '../../../stateManagement/SettingsContext';

interface SectionSettingsMoleculeProps {
  sectionIcons: SectionIcons[];
  onSectionIconChange: (sectionIcons: SectionIcons[]) => void;
}

export const SectionsSettingsMolecule: React.FC<SectionSettingsMoleculeProps> = ({
  sectionIcons,
  onSectionIconChange,
}) => {
  const [editingSectionIcon, setEditingSectionIcon] = useState<string | null>(null);
  const [currentEdit, setCurrentEdit] = useState<SectionIcons | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleEditSectionIcons = (section: SectionIcons) => {
    setEditingSectionIcon(section.id);
    setCurrentEdit({ ...section });
  };

  const handleSaveEdit = () => {
    if (currentEdit && editingSectionIcon) {
      const updatedSections = sectionIcons.map(section =>
        section.id === editingSectionIcon ? currentEdit : section,
      );
      onSectionIconChange(updatedSections);
      setEditingSectionIcon(null);
      setCurrentEdit(null);
    }
  };

  const handleIconChange = (iconName: string) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, icon: iconName.split('.')[1] });
    }
  };

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSubmit = () => {
    // eslint-disable-next-line
    console.log(sectionIcons);
  };

  return (
    <Box>
      <HStack
        justifyContent="space-between"
        border="1px solid #dedede"
        borderRadius="10px"
        p="2"
        backgroundColor="#f1f2f4"
      >
        <CustomText variant="h1" size="md" data-qa="menucards">
          Section Icons
        </CustomText>
        <HStack spacing={2}>
          <Icon
            as={isExpanded ? ChevronUpIcon : ChevronDownIcon}
            boxSize={6}
            onClick={toggleExpandCollapse}
            cursor="pointer"
            aria-label={isExpanded ? 'Collapse' : 'Expand'}
          />
        </HStack>
      </HStack>

      {isExpanded && (
        <Stack spacing={4}>
          <Box mt={6}>
            {sectionIcons.map(section => (
              <HStack
                key={section.id}
                p={4}
                borderWidth="1px"
                borderRadius="md"
                mb={4}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {editingSectionIcon === section.id ? (
                  <Stack spacing={2} width="100%">
                    <HStack>
                      <IconPicker onSelect={iconName => handleIconChange(iconName)} />
                      {currentEdit?.icon && (
                        <Icon
                          as={getIconFromString(currentEdit.icon) || FaRegQuestionCircle}
                          boxSize={6}
                        />
                      )}
                    </HStack>
                  </Stack>
                ) : (
                  <Stack spacing={1} width="50%">
                    <HStack alignItems="center">
                      <Icon
                        aria-label="Section Icon"
                        as={getIconFromString(section.icon) || FaRegQuestionCircle}
                        mr={4}
                      />
                      <CustomText fontWeight="bold">{section.name}</CustomText>
                    </HStack>
                  </Stack>
                )}

                <HStack>
                  {editingSectionIcon === section.id ? (
                    <CustomButton
                      icon={<FaCheck />}
                      aria-label="Save Changes"
                      onClick={handleSaveEdit}
                      colorScheme="green"
                    />
                  ) : (
                    <CustomButton
                      icon={<FaEdit />}
                      aria-label="Edit Section Icon"
                      onClick={() => handleEditSectionIcons(section)}
                      colorScheme="yellow"
                    />
                  )}
                </HStack>
              </HStack>
            ))}
          </Box>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </Stack>
      )}
    </Box>
  );
};
