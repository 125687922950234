// ArticleContent.tsx
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CustomText } from '../../atoms';
import { ArticleFeedback } from '../../molecules/FaqPage.tsx/ArticleFeedback';
import { useSettings } from '../../../stateManagement/SettingsContext';

interface ArticleContentProps {
  title: string;
  content: string;
  lastUpdated: string;
  textColor?: string;
  bgColor?: string;
  sectionName?: string;
}

export const ArticleContent: React.FC<ArticleContentProps> = ({
  title,
  content,
  lastUpdated,
  textColor = '#000',
  bgColor = '#fff',
  sectionName,
}) => {
  const navigate = useNavigate();
  const language = useSettings;
  return (
    <Box p="10" backgroundColor={bgColor}>
      <Breadcrumb
        separator="/" // Sets the separator between breadcrumb items
        fontSize="xs"
        color="gray.600"
      >
        {/* Home Breadcrumb Item */}
        <BreadcrumbItem>
          <BreadcrumbLink href="/" color="blue.500">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>

        {/* Category Name Breadcrumb Item */}
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            onClick={() =>
              navigate(
                `/sections/${language}/section/${sectionName?.toLowerCase().replace(/\s+/g, '-')}`,
              )
            }
            color="blue.500"
            _hover={{ textDecoration: 'underline' }}
            cursor="pointer"
          >
            {sectionName}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Box my="10">
        <CustomText variant="h1" textColor={textColor}>
          {title}
        </CustomText>
      </Box>
      <Box mb="10">
        <CustomText
          textColor={textColor}
          variant="body"
          fontSize="sm"
        >{`Last updated ${lastUpdated}`}</CustomText>
      </Box>
      <Box
        className="article-content"
        dangerouslySetInnerHTML={{ __html: content }}
        textColor={textColor}
      />
      <ArticleFeedback />
    </Box>
  );
};
