import React from 'react';
import { Box } from '@chakra-ui/react';
import { Layout } from '../templates';
import { TicketData, TicketView } from '../organisms/TicketView';
import { useSettings } from '../../stateManagement/SettingsContext';

export const TicketPage: React.FC = () => {
  const ticketData: TicketData = {
    ticketId: '20345',
    status: 'Resolved',
    messages: [
      {
        sender: 'ananya.gupta@example.com',
        timestamp: '2024-08-13T16:02:00Z',
        message:
          'Hi,<br />As soon as I updated my game to the latest version, I lost my progress. Tried logging in multiple times, still the same. <br /> Please help.',
      },
      {
        sender: 'HelpShift Support Bot',
        timestamp: '2024-08-13T16:03:00Z',
        message:
          'Hi there,<br />I have escalated this ticket to second line support, they will get back to you soon.<br />Best regards',
      },
    ],
  };
  const { settings } = useSettings();
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="76vh"
        backgroundColor={settings?.configs.primaryBgColor}
      >
        <Box flex={1}>
          <TicketView ticketData={ticketData} />
        </Box>
      </Box>
    </Layout>
  );
};
