import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { MenuCard } from '../molecules/MenuCard';

// Define the type for each item in the items array
export interface MenuCardItem {
  menuCardLink: string;
  icon: string;
  title: string;
  id: string;
}

// Define props interface
interface MenuCardListProps {
  items: MenuCardItem[] | undefined;
  menuCardsBgColor: string;
  menuCradsTextColor: string;
  onClickMenuCard: (menuCardLink: string) => void;
}

export const MenuCardList: React.FC<MenuCardListProps> = ({
  items,
  menuCardsBgColor,
  menuCradsTextColor,
  onClickMenuCard,
}) => {
  return (
    <Box display="flex" justifyContent="center" py="2vh" width="100%">
      <SimpleGrid
        columns={{ base: items?.length, sm: Math.min(items?.length ?? 1, 5) }}
        spacing={4}
        maxWidth="1200px"
        width="100%"
      >
        {items?.map(item => (
          <MenuCard
            key={item.id}
            icon={item.icon}
            title={item.title}
            bg={menuCardsBgColor}
            textColor={menuCradsTextColor}
            onClickMenuCard={() => onClickMenuCard(item.menuCardLink)}
            id={item.id}
            menuCardLink={item.menuCardLink}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
