/**
 * A type representing the Client ID, which is a string.
 */
type ClientId = string;

/**
 * Interface defining the structure of a color scheme for type safety.
 *
 * @interface
 * @property {string} primary - The primary color used for the client.
 * @property {string} secondary - The secondary color used for the client.
 * @property {string} accent - The accent color used for highlights.
 * @property {string} borderColor - The border color used for UI components.
 * @property {string} fontColor - The default font color.
 * @property {object} modes - Defines different color modes (light and dark).
 * @property {object} modes.light - Light mode configuration.
 * @property {string} modes.light.bg - Background color for light mode.
 * @property {string} modes.light.text - Text color for light mode.
 * @property {object} modes.dark - Dark mode configuration.
 * @property {string} modes.dark.bg - Background color for dark mode.
 * @property {string} modes.dark.text - Text color for dark mode.
 */
interface ColorScheme {
  primary: string;
  secondary: string;
  accent: string;
  borderColor: string;
  fontColor: string;
  modes: {
    light: {
      bg: string;
      text: string;
    };
    dark: {
      bg: string;
      text: string;
    };
  };
}

/**
 * Record that maps each `ClientId` to its respective color scheme.
 *
 * This object stores predefined color schemes for clients like `yankees` and `jets`.
 */
const colors: Record<ClientId, ColorScheme> = {
  yankees: {
    primary: '#001A57', // Navy Blue
    secondary: '#FFFFFF', // White
    accent: '#C8102E', // Red
    borderColor: '#A5ACAF', // Gray
    fontColor: '#1C1C1C', // White
    modes: {
      light: {
        bg: '#F0F0F0', // Light Gray
        text: '#333333', // Dark Text
      },
      dark: {
        bg: '#001A57', // Navy Blue
        text: '#FFFFFF', // White Text
      },
    },
  },
  jets: {
    primary: '#203731', // Green
    secondary: '#FFFFFF', // White
    accent: '#869397', // Metallic Silver
    borderColor: '#1C1C1C', // Dark Gray
    fontColor: '#1C1C1C',
    modes: {
      light: {
        bg: '#E8F5E9', // Light Green Background
        text: '#155724', // Dark Green Text
      },
      dark: {
        bg: '#1C1C1C', // Dark Gray Background
        text: '#E8F5E9', // Light Green Text
      },
    },
  },
};

/**
 * Simulates an API call to fetch the color scheme for a specific client.
 *
 * This function asynchronously fetches the color configuration for a given clientId.
 * It mimics an API call by using a delay and returning a predefined set of colors.
 *
 * @param {ClientId} clientId - The client identifier for which to fetch the color palette.
 * @returns {Promise<ColorScheme>} A promise that resolves to the color scheme of the specified client.
 * @throws {Error} Will throw an error if no color scheme is found for the given `clientId`.
 */
export const fetchColors = async (clientId: ClientId): Promise<ColorScheme> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const clientColors = colors[clientId];
      if (clientColors) {
        resolve(clientColors);
      } else {
        // eslint-disable-next-line
        console.error(
          `Error fetching colors: No color configuration found for clientId "${clientId}"`,
        );
        reject(new Error(`No color configuration found for clientId "${clientId}"`));
      }
    }, 500); // Simulate a 500ms delay
  });
};
