import { extendTheme, ThemeConfig } from '@chakra-ui/react';

/**
 * Chakra UI theme configuration.
 *
 * This configuration object determines the initial color mode for the application
 * and whether the system's color mode is used. The color mode is set to 'light' by default,
 * and the use of the system color mode is disabled.
 *
 * @type {ThemeConfig}
 * @property {string} initialColorMode - Sets the initial color mode of the application ('light' or 'dark').
 * @property {boolean} useSystemColorMode - If true, the theme will use the system's color mode; otherwise, it defaults to the specified initial color mode.
 */
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

/**
 * Default color scheme for the application.
 *
 * This object defines the primary, secondary, accent, border, and font colors
 * for the theme, along with separate color modes for 'light' and 'dark' themes.
 *
 * @type {object}
 * @property {string} primary - The primary color used across the application (default: '#3182ce').
 * @property {string} secondary - The secondary color used across the application (default: '#2D3748').
 * @property {string} accent - The accent color used for highlights (default: '#38B2AC').
 * @property {string} borderColor - The border color used in UI components (default: '#E2E8F0').
 * @property {string} fontColor - The default font color (default: '#2D3748').
 * @property {object} modes - Defines different color modes (light and dark).
 * @property {object} modes.light - Light mode color scheme.
 * @property {string} modes.light.bg - Background color for light mode (default: '#FFFFFF').
 * @property {string} modes.light.text - Text color for light mode (default: '#2D3748').
 * @property {object} modes.dark - Dark mode color scheme.
 * @property {string} modes.dark.bg - Background color for dark mode (default: '#1A202C').
 * @property {string} modes.dark.text - Text color for dark mode (default: '#FFFFFF').
 */
const defaultColors = {
  primary: '#3182ce',
  secondary: '#2D3748',
  accent: '#38B2AC',
  borderColor: '#E2E8F0',
  fontColor: '#2D3748',
  modes: {
    light: {
      bg: '#FFFFFF',
      text: '#2D3748',
    },
    dark: {
      bg: '#1A202C',
      text: '#FFFFFF',
    },
  },
};

/**
 * The default theme object, extended from the Chakra UI theme.
 *
 * This theme merges the provided configuration (`config`) and color schemes (`defaultColors`)
 * to create a unified theme for the application.
 *
 * @returns {object} The extended Chakra UI theme.
 */
export const defaultTheme = extendTheme({ config, colors: defaultColors });
