import { Navigate, Outlet } from 'react-router-dom';

/**
 * A component that protects routes by checking authentication status.
 *
 * This component renders the child components (via `Outlet`) only if the user
 * is authenticated. If the user is not authenticated, it redirects to the
 * login page using the `Navigate` component from `react-router-dom`.
 *
 * This is useful for preventing access to certain routes in the application
 * that require user authentication.
 *
 * @component
 * @example
 * <ProtectedRoute isAuthenticated={userIsAuthenticated} />
 *
 * @param {Object} props - The props for the protected route component.
 * @param {boolean} props.isAuthenticated - A boolean indicating whether the user is authenticated.
 * @returns {JSX.Element} Either the child routes (via `Outlet`) or a redirect to the login page.
 */
export const ProtectedRoute = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  // eslint-disable-next-line no-console
  console.log('isauth', isAuthenticated);

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};
