// LandingPagePreview.tsx
import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { MenuCardList } from '../organisms/MenuCardList';
import { NewsSection } from '../organisms/NewsSection';
import { PopularArticles } from '../organisms/PopularArticles';
import { BannerWithSearchBar } from '../organisms/BannerWithSearchBar';
import { ContactBtnSection } from '../molecules/ContactUsBtnSection';
import { Header } from '../organisms/Header';
import { Footer } from '../organisms/Footer';
import { SectionGrid } from '../organisms/SectionGrid';
import { SectionData } from '../../stateManagement/SettingsContext';

interface LandingPagePreviewProps {
  settings: any;
  sectionsData: SectionData[] | null;
}

export const LandingPagePreview: React.FC<LandingPagePreviewProps> = ({
  settings,
  sectionsData,
}) => {
  const [currentLanguage, setCurrentLanguage] = useState('English');
  const onclickMenuCardPreview = () => {};
  const onClickPopularArticlePreview = () => {};
  const onButtonClick = () => {};
  const handleLanguageChange = () => {
    setCurrentLanguage('english');
  };
  const changeTheme = () => {};
  const onClickMyTickets = () => {};

  return (
    <Box
      border="1px solid #ccc"
      borderRadius="8px"
      overflow="hidden"
      margin="auto"
      backgroundColor={settings.configs.primaryBgColor}
    >
      {/* Header */}
      {settings?.configs?.showHeader && (
        <Header
          showMainHeader={settings?.configs.showMainHeader}
          mainHeaderBgColor={settings?.configs.mainHeaderBgColor}
          mainHeaderTextColor={settings?.configs.mainHeaderTextColor}
          showSubHeader={settings?.configs.showSubHeader}
          subHeaderBgColor={settings?.configs.primaryBgColor}
          subHeaderTextColor={settings?.configs.primaryTextColor}
          showHeaderLogo={settings?.configs.showHeaderLogo}
          logoUrl={settings?.configs.headerlogoUrl}
          gameTitle={settings?.data.gameTitle}
          platforms={settings?.data.platforms}
          languages={settings?.data.languages}
          currentLanguage={currentLanguage}
          onLanguageChange={handleLanguageChange}
          horizontalPadding={{ base: '1', md: '8vw', lg: '12vw' }} // Responsive padding
          changeTheme={changeTheme}
          onClickMyTickets={onClickMyTickets}
        />
      )}
      {settings?.configs.showBanner && (
        <BannerWithSearchBar
          imageSrc={settings.configs.bannerImageUrl}
          altText="Banner image"
          bannerText="How can we help you?"
          placeholderText="Search for articles"
          placeholderFontSize={{ base: '18px', md: '20px', lg: '22px' }}
          placeholderColor={settings.configs.placeholderColor}
          searchBarTextColor={settings.configs.searchBarTextColor}
          showOverlay={settings?.configs.showOverlay}
          showSearchBar={settings?.configs.showSearchBar}
        />
      )}
      <Box px={{ base: '2vw', md: '1vw', lg: '7vw' }}>
        {settings?.configs.showMenuCards && (
          <MenuCardList
            items={settings.data.menuCards}
            menuCardsBgColor={settings.configs.secondaryBgColor}
            menuCradsTextColor={settings.configs.secondaryTextColor}
            onClickMenuCard={onclickMenuCardPreview}
          />
        )}
        {settings?.configs.showNewsSection && (
          <NewsSection
            newsArticles={settings.data.newsArticles}
            newsArticleBgColor={settings.configs.primaryBgColor}
            newsArticleTextColor={settings.configs.primaryTextColor}
            newsSectionBgColor={settings.configs.secondaryBgColor}
            newsSectionHeadingTextColor={settings.configs.secondaryTextColor}
            paginationSelectedPageBgColor={settings.configs.paginationSelectedPageBgColor}
            paginationSelectedPageTextColor={settings.configs.paginationSelectedPageTextColor}
          />
        )}
        {settings?.configs.showPopularArticles && (
          <PopularArticles
            faqArticles={settings.data.popularArticles}
            bgColor={settings.configs.primaryBgColor}
            faqArticlesTextColor={settings.configs.secondaryTextColor}
            faqArticlesBgColor={settings.configs.secondaryBgColor}
            primaryTextColor={settings.configs.primaryTextColor}
            onClickPopularArticle={onClickPopularArticlePreview}
          />
        )}

        {/* section wise faq display */}
        <SectionGrid
          textColor={settings?.configs.secondaryTextColor}
          sectionBgColor={settings?.configs.secondaryBgColor}
          sections={sectionsData}
          sectionIcons={settings?.data.sectionIcons}
        />
      </Box>
      {settings?.configs.showContactUsButton && (
        <ContactBtnSection
          variant="h1"
          color={settings.configs.contactUsButtonTextColor}
          buttonBgColor={settings.configs.contactUsButtonBgColor}
          needHelpText={settings.configs.needHelptext}
          needHelpTextColor={settings.configs.needHelpTextColor}
          onButtonClick={onButtonClick}
        />
      )}
      {/* Footer */}
      <Footer
        showFooterLogo={settings?.configs?.showFooterLogo}
        showFooterLinks={settings?.configs?.showFooterLinks}
        links={settings?.data?.footerLinks}
        imageSrc={settings?.configs?.footerLogoUrl}
        logoWidth={settings?.configs?.footerLogoSize}
        footerNote={settings?.data?.footerNote}
        footerBgColor={settings?.configs?.footerBackgroundColor}
        footerTextColor={settings?.configs?.footerTextColor}
      />
    </Box>
  );
};
