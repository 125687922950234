/**
 * Entry point of the React application.
 *
 * This component wraps the entire app with essential providers:
 *
 * - `Provider`: Wraps the application with the Redux store, allowing access to global state.
 * - `PersistGate`: Delays the rendering of the app until the persisted state is rehydrated, ensuring data from the store is restored properly.
 * - `CustomThemeProvider`: Provides the custom Chakra UI theme to the entire app, including colors fetched asynchronously based on the client ID.
 *
 * The app is rendered into a DOM element with the ID 'root'.
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { CustomThemeProvider } from './theme/CustomThemeProvider';
import { App } from './App';
import { store, persistor } from './stateManagement/store';

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomThemeProvider>
          <App />
        </CustomThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
