import React from 'react';
import { Box, Heading, Text, ResponsiveValue } from '@chakra-ui/react';

interface HeadingProps {
  question: string;
  fontSize?: string;
  fontWeight?: string | number;
  color?: string;
  textAlign?: 'left' | 'center' | 'right';
  marginStart?: ResponsiveValue<string>;
  marginTop?: string;
  variant?: 'outline' | 'solid';
  bgColor?: string;
  paddingLeft?: ResponsiveValue<string>;
  description?: string; // Optional description prop
  descriptionColor?: string;
  descriptionFontSize?: string;
}

const getDescriptionFontSize = (fontSize: string | undefined): string => {
  // Define a relative size for the description based on the heading's fontSize
  const fontSizeMap: { [key: string]: string } = {
    '5xl': '2xl',
    '4xl': 'xl',
    '3xl': 'lg',
    '2xl': 'md',
    xl: 'md',
    lg: 'sm',
    md: 'sm',
    sm: 'xs',
    xs: 'xs',
  };

  return fontSizeMap[fontSize || 'md'] || 'sm'; // Default to 'sm' if undefined
};

export const CustomHeading: React.FC<HeadingProps> = ({
  question,
  fontSize = 'md',
  fontWeight = 400,
  color = 'black',
  textAlign = 'left',
  marginStart,
  marginTop = '3rem',
  variant = 'outline',
  bgColor,
  paddingLeft,
  description,
  descriptionColor = 'gray.600',
  descriptionFontSize,
}) => {
  const computedDescriptionFontSize = descriptionFontSize || getDescriptionFontSize(fontSize);

  return (
    <Box
      as="div"
      display="block"
      marginTop={marginTop}
      paddingLeft={paddingLeft}
      textAlign={textAlign}
      width="100%"
    >
      <Heading
        marginTop={marginTop}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color}
        textAlign={textAlign}
        marginStart={marginStart}
        variant={variant}
        bgColor={bgColor}
        fontFamily="'Bebas Neue', sans-serif"
      >
        {question}
      </Heading>
      {description && (
        <Text
          fontSize={computedDescriptionFontSize}
          color={descriptionColor}
          marginStart={marginStart}
          marginTop="0.5rem"
          marginBottom="3rem"
        >
          {description}
          <Box
            borderBottom="1px solid var(--Border-Dark, #C8CACC)"
            width="100%"
            marginTop="2rem"
            marginBottom="3rem"
          />
        </Text>
      )}
    </Box>
  );
};
