import { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import * as FaIcons from 'react-icons/fa';
import { CustomButton, CustomInput, CustomText } from '../../atoms';

// Create a list of available FA Icons from react-icons
const allFaIcons = Object.keys(FaIcons).map(iconName => ({
  name: iconName,
  component: FaIcons[iconName as keyof typeof FaIcons], // Fix for indexing
}));

// IconPicker Component
export const IconPicker = ({ onSelect }: { onSelect: (iconName: string) => void }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Filter icons based on the search term
  const filteredIcons = allFaIcons.filter(icon =>
    icon.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <>
      <Button onClick={onOpen}>Pick an Icon</Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose an Icon</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CustomInput
              placeholder="Search icons..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              mb={4}
            />
            <VStack spacing={3} overflowY="scroll" maxHeight="300px" align="start">
              {filteredIcons.map(icon => (
                <CustomButton
                  key={icon.name}
                  variant="ghost"
                  justifyContent="start"
                  onClick={() => {
                    onSelect(`FaIcons.${icon.name}`);
                    onClose();
                  }}
                  w="100%"
                >
                  <Icon as={icon.component} boxSize={6} mr={2} />
                  <CustomText>{icon.name}</CustomText>
                </CustomButton>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
