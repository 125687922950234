import {
  Box,
  Input as ChakraInput,
  InputProps,
  ResponsiveValue,
  FormLabel,
} from '@chakra-ui/react';
import React, { ChangeEventHandler } from 'react';

interface CustomInputProps extends InputProps {
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  size?: string;
  value?: string | number;
  type?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  variant?: string;
  bgColor?: string;
  height?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  fontSize?: ResponsiveValue<string>;
  placeholderColor?: string; // Add placeholder color prop
  inputTextColor?: string; // Add input text color prop

  label?: string;
  labelColor?: string;
  labelSize?: string;
  marginTop?: string;
  width?: ResponsiveValue<string>;
  textOverflow?: string;
}

export const CustomInput: React.FC<CustomInputProps> = ({
  label,
  labelColor,
  labelSize,
  placeholder,
  onChange,
  size = 'md',
  value,
  type = 'text',
  isDisabled = false,
  isInvalid = false,
  variant = 'outline',
  bgColor = '#fff',
  height,
  paddingLeft,
  fontSize,
  placeholderColor = '#A0AEC0', // Default placeholder color (Chakra's gray.400)
  inputTextColor = '#000', // Default input text color (black)
  width,
  textOverflow,
  marginTop,
}) => {
  return (
    <Box width="100%" shadow="none" marginTop={marginTop}>
      {label && (
        <FormLabel color={labelColor} fontSize={labelSize} fontWeight="bold">
          {label}
        </FormLabel>
      )}
      <ChakraInput
        placeholder={placeholder}
        onChange={onChange}
        size={size}
        value={value}
        type={type}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        variant={variant}
        backgroundColor={bgColor}
        height={height}
        paddingLeft={paddingLeft}
        fontSize={fontSize}
        color={inputTextColor} // Set input text color
        width={width}
        textOverflow={textOverflow}
        sx={{
          '::placeholder': {
            color: placeholderColor, // Set placeholder color
          },
        }}
      />
    </Box>
  );
};
