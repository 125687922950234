// src/molecules/MainHeader.tsx
import { useState } from 'react';
import { Box, Flex, Spacer, ResponsiveValue, IconButton } from '@chakra-ui/react';
import { FaMoon, FaSun, FaUser } from 'react-icons/fa';
// import tinycolor from 'tinycolor2';
import { CustomImage } from '../atoms/CustomImage';
import { CustomText } from '../atoms/CustomText';
import { CustomButton } from '../atoms/CustomButton';

interface MainHeaderProps {
  logoUrl?: string; // Type for logo URL
  logoSize?: ResponsiveValue<string>;
  gameTitle: string; // Type for game title
  horizontalPadding?: ResponsiveValue<string>; // Optional horizontal padding type
  darkMode?: boolean;
  marginStart?: ResponsiveValue<string>;
  showHeaderLogo?: boolean;
  mainHeaderBgColor: string;
  mainHeaderTextColor: string;
  changeTheme?: any;
}

export const MainHeader: React.FC<MainHeaderProps> = ({
  logoUrl,
  gameTitle,
  logoSize,
  marginStart,
  showHeaderLogo,
  mainHeaderBgColor = '#fefefe',
  mainHeaderTextColor,
  changeTheme,
}) => {
  const [darkMode, setDarkMode] = useState(false);

  // Toggle dark/light mode
  const handleToggleTheme = () => {
    setDarkMode(!darkMode);
    if (!darkMode) {
      changeTheme('#0000ff', '#ff00ff'); // New colors when toggled on
    } else {
      changeTheme('#ff0000', '#00ff00'); // Reset to original colors when toggled off
    }
  };

  return (
    <Box px="2" backgroundColor={mainHeaderBgColor}>
      <Flex
        alignItems="center"
        flexDirection={{ base: 'column', sm: 'row' }} // Stack on small screens, row on larger
        justifyContent="space-between"
      >
        {/* Logo Section */}
        <Flex alignItems="center" ml={{ base: 2, lg: 2, sm: 0 }}>
          {
            /* Margin bottom on small screens */
            showHeaderLogo && (
              <CustomImage
                src={logoUrl}
                alt="Logo"
                width={logoSize}
                color={mainHeaderTextColor}
                link="/"
              />
            )
          }
          <Box width="100%">
            <CustomText
              fontSize="lg"
              fontWeight="bold"
              marginStart={marginStart}
              color={mainHeaderTextColor}
            >
              {gameTitle}
            </CustomText>
          </Box>
        </Flex>
        <Spacer />

        {/* Right-side Navigation Items */}
        <Flex alignItems="center" justifyContent={{ base: 'center', sm: 'flex-end' }}>
          {/* Login Button */}
          <CustomButton
            ml={{ base: 0, sm: 2 }} // No margin on small screens, margin on larger
            leftIcon={<FaUser />}
            backgroundColor={mainHeaderBgColor}
            color={mainHeaderTextColor}
            _hover={{
              boxShadow: 'lg',
              transform: 'scale(1.05)',
              // backgroundColor: tinycolor(mainHeaderBgColor).lighten(40).toString(),
            }}
          >
            Login
          </CustomButton>

          {/* Dark/Light Mode Switcher */}
          <IconButton
            icon={darkMode ? <FaSun /> : <FaMoon />}
            aria-label="Toggle Theme"
            variant="ghost"
            onClick={handleToggleTheme}
            ml={{ base: 2, sm: 0 }} // Add margin for small screens only
            bg={mainHeaderBgColor}
            color={mainHeaderTextColor}
            _hover={{ boxShadow: 'md', transform: 'scale(1.05)' }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
