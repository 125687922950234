import React, { useState } from 'react';
import { Box, Icon, Stack, HStack } from '@chakra-ui/react';
import { FaEdit, FaTrash, FaCheck, FaPlus, FaRegQuestionCircle } from 'react-icons/fa';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { IconPicker } from './iconPicker';
import { getIconFromString } from '../MenuCard';
import { CustomButton, CustomInput, CustomText } from '../../atoms';

interface MenuCard {
  menuCardLink?: string;
  icon: string;
  title: string;
  id: string;
}

interface MenuCardSettingsOrganismProps {
  menuCards: MenuCard[];
  onMenuCardChange: (newCards: MenuCard[]) => void;
}

export const MenuCardSettingsOrganism: React.FC<MenuCardSettingsOrganismProps> = ({
  menuCards,
  onMenuCardChange,
}) => {
  const [editingCardId, setEditingCardId] = useState<string | null>(null);
  const [currentEdit, setCurrentEdit] = useState<MenuCard | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleEditMenuCard = (card: MenuCard) => {
    setEditingCardId(card.id);
    setCurrentEdit({ ...card });
  };

  const handleSaveEdit = () => {
    if (currentEdit && editingCardId) {
      const updatedCards = menuCards.map(card => (card.id === editingCardId ? currentEdit : card));
      onMenuCardChange(updatedCards);
      setEditingCardId(null);
      setCurrentEdit(null);
    }
  };

  const handleDeleteMenuCard = (id: string) => {
    const updatedCards = menuCards.filter(card => card.id !== id);
    onMenuCardChange(updatedCards);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, title: event.target.value });
    }
  };

  const handleIconChange = (iconName: string) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, icon: iconName.split('.')[1] });
    }
  };

  const handleMenuCardLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentEdit) {
      setCurrentEdit({ ...currentEdit, menuCardLink: event.target.value });
    }
  };

  const handleAddMenuCard = () => {
    const newCard: MenuCard = {
      id: Date.now().toString(),
      icon: '',
      title: '',
      menuCardLink: '',
    };
    onMenuCardChange([newCard, ...menuCards]);
    setEditingCardId(newCard.id);
    setCurrentEdit(newCard);
  };

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box>
      <HStack
        justifyContent="space-between"
        border="1px solid #dedede"
        borderRadius="10px"
        p="2"
        backgroundColor="#f1f2f4"
      >
        <CustomText variant="h1" size="md" data-qa="menucards">
          Menu Cards
        </CustomText>
        <HStack spacing={2}>
          <CustomButton
            icon={<FaPlus />}
            aria-label="Add Menu Card"
            onClick={handleAddMenuCard}
            colorScheme="green"
          />
          <Icon
            as={isExpanded ? ChevronUpIcon : ChevronDownIcon}
            boxSize={6}
            onClick={toggleExpandCollapse}
            cursor="pointer"
            aria-label={isExpanded ? 'Collapse' : 'Expand'}
          />
        </HStack>
      </HStack>

      {isExpanded && (
        <Stack spacing={4}>
          <Box mt={6}>
            {menuCards.map(card => (
              <HStack
                key={card.id}
                p={4}
                borderWidth="1px"
                borderRadius="md"
                mb={4}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {editingCardId === card.id ? (
                  <Stack spacing={2} width="100%">
                    <HStack>
                      <IconPicker onSelect={iconName => handleIconChange(iconName)} />
                      {currentEdit?.icon && (
                        <Icon
                          as={getIconFromString(currentEdit.icon) || FaRegQuestionCircle}
                          boxSize={6}
                        />
                      )}
                      <CustomInput
                        value={currentEdit?.title || ''}
                        onChange={handleTitleChange}
                        placeholder="Menu Card Title"
                        overflow="auto"
                      />
                    </HStack>
                    <CustomInput
                      value={currentEdit?.menuCardLink || ''}
                      onChange={handleMenuCardLinkChange}
                      placeholder="Menu Card Link"
                      size="sm"
                      textOverflow="ellipsis"
                    />
                  </Stack>
                ) : (
                  <Stack spacing={1} width="50%">
                    <HStack alignItems="center">
                      <Icon
                        aria-label="Menu Icon"
                        as={getIconFromString(card.icon) || FaRegQuestionCircle}
                        mr={4}
                      />
                      <CustomText fontWeight="bold">{card.title}</CustomText>
                    </HStack>
                    <CustomText color="gray.500" overflow="ellipsis" whiteSpace="nowrap">
                      {card.menuCardLink}
                    </CustomText>
                  </Stack>
                )}

                <HStack>
                  {editingCardId === card.id ? (
                    <CustomButton
                      icon={<FaCheck />}
                      aria-label="Save Changes"
                      onClick={handleSaveEdit}
                      colorScheme="green"
                    />
                  ) : (
                    <CustomButton
                      icon={<FaEdit />}
                      aria-label="Edit Menu Card"
                      onClick={() => handleEditMenuCard(card)}
                      colorScheme="yellow"
                    />
                  )}
                  <CustomButton
                    icon={<FaTrash />}
                    aria-label="Delete Menu Card"
                    onClick={() => handleDeleteMenuCard(card.id)}
                    colorScheme="red"
                  />
                </HStack>
              </HStack>
            ))}
          </Box>
        </Stack>
      )}
    </Box>
  );
};
