import { Box } from '@chakra-ui/react';
import React from 'react';
import { CustomText } from '../atoms/CustomText';

interface ArticleCardProps {
  id: string | number;
  category: string;
  title: string;
  categoryTextColor: string;
  faqArticlesBgColor: string;
  faqArticlesTextColor: string;
  onClickPopularArticle: (id: string | number) => void;
}

export const ArticleCard: React.FC<ArticleCardProps> = ({
  id,
  category,
  title,
  categoryTextColor,
  faqArticlesBgColor,
  faqArticlesTextColor,
  onClickPopularArticle,
}) => {
  return (
    <Box
      p="10"
      border="0.1px solid #f1f2f4"
      boxShadow="sm"
      _hover={{ boxShadow: 'md', transform: 'scale(1.05)' }}
      cursor="pointer"
      maxW="100%"
      bg={faqArticlesBgColor}
      height="15vh"
      onClick={() => onClickPopularArticle(id)}
    >
      {/* Category Text */}
      <CustomText variant="body" fontSize="sm" fontWeight="normal" color={categoryTextColor} mb="2">
        {category}
      </CustomText>

      {/* Article Title */}
      <CustomText variant="h3" fontSize="lg" fontWeight="bold" color={faqArticlesTextColor}>
        {title}
      </CustomText>
    </Box>
  );
};
