import { FormHelperText, ResponsiveValue } from '@chakra-ui/react';
import React from 'react';

interface DescriptionProps {
  description?: string;
  fontSize?: string | number;
  color?: string;
  paddingLeft?: ResponsiveValue<string>;
  marginStart?: ResponsiveValue<string>;
  marginTop?: string | number;
  marginBottom?: string | number;
}

export const Description: React.FC<DescriptionProps> = ({
  description,
  fontSize = 'md',
  color = 'gray.500',
  paddingLeft = '0',
  marginStart = '0rem',
  marginTop = '0.5rem',
  marginBottom = '0rem',
}) =>
  description ? (
    <FormHelperText
      fontSize={fontSize}
      color={color}
      paddingLeft={paddingLeft}
      marginStart={marginStart}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {description}
    </FormHelperText>
  ) : null;
