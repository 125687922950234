import React, { useState } from 'react';
import { Checkbox, CheckboxGroup, CheckboxProps, ResponsiveValue } from '@chakra-ui/react';

interface CustomCheckboxProps extends CheckboxProps {
  question: string;
  isChecked?: boolean;
  color?: string;
  fontWeight?: ResponsiveValue<string>;
  textAlign?: 'left' | 'right' | 'center' | 'justify';
  isDisabled?: boolean;
  isRequired?: boolean;
  marginStart?: ResponsiveValue<string>;
  paddingLeft?: ResponsiveValue<string>;
  marginTop?: string;
  fontSize?: string;
}

export const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  question,
  isChecked = false,
  color = 'black',
  fontSize,
  fontWeight,
  textAlign = 'left',
  marginStart,
  isDisabled = false,
  isRequired = false,
  paddingLeft,
  marginTop,
}) => {
  const [checked, setChecked] = useState(isChecked);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  return (
    <CheckboxGroup>
      <Checkbox
        isChecked={checked}
        isDisabled={isDisabled}
        onChange={handleChange}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textAlign={textAlign}
        marginStart={marginStart}
        isRequired={isRequired}
        paddingLeft={paddingLeft}
        marginTop={marginTop}
      >
        {question}
      </Checkbox>
    </CheckboxGroup>
  );
};
