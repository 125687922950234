import React from 'react';
import { Box } from '@chakra-ui/react';
import { Layout } from '../templates';
import { PlayerHub, PlayerHubTableField } from '../organisms/PlayerHub';
import { useSettings } from '../../stateManagement/SettingsContext';

export const PlayerHubPage: React.FC = () => {
  const PlayerHubFields: PlayerHubTableField[] = [
    {
      ticketNumber: 20347,
      ticketSummary: 'Lost in-game items',
      status: 'Resolved',
      date: '03-Aug-24',
      platform: 'Play Station',
    },
    {
      ticketNumber: 20348,
      ticketSummary: 'Payment not processed',
      status: 'Resolved',
      date: '04-Aug-24',
      platform: 'PC',
    },
    {
      ticketNumber: 20349,
      ticketSummary: 'Game lagging frequently',
      status: 'Resolved',
      date: '05-Aug-24',
      platform: 'Mobile iOS',
    },
    {
      ticketNumber: 20350,
      ticketSummary: 'Audio issues during gameplay',
      status: 'Resolved',
      date: '06-Aug-24',
      platform: 'Mobile Android',
    },
    {
      ticketNumber: 20351,
      ticketSummary: 'Unable to install game update',
      status: 'Resolved',
      date: '07-Aug-24',
      platform: 'Mobile Android',
    },
    {
      ticketNumber: 20353,
      ticketSummary: 'Graphics not rendering properly',
      status: 'Resolved',
      date: '09-Aug-24',
      platform: 'Mobile Android',
    },
  ];
  const { settings } = useSettings(); // Destructure from context
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="76vh"
        backgroundColor={settings?.configs.primaryBgColor}
      >
        <Box flex={1}>
          <PlayerHub PlayerHubTableFields={PlayerHubFields} />
        </Box>
      </Box>
    </Layout>
  );
};
