import React from 'react';
import { InputGroup, InputLeftElement, Box, ResponsiveValue } from '@chakra-ui/react';
import tinycolor from 'tinycolor2';

import { CustomInput } from '../atoms/CustomInput';
import { CustomIcon } from '../atoms/CustomIcon'; // Icon Atom

interface InputWithIconProps {
  placeholder?: string;
  width?: ResponsiveValue<string>; // Type for width
  height?: ResponsiveValue<string>; // Type for height
  iconSize?: string; // Type for icon size
  iconColor?: string; // Type for icon color
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; // Type for onChange event
  value: string; // Type for input value
  placeholderFontFamily?: string; // Type for placeholder font family
  placeholderFontSize?: ResponsiveValue<string>; // Type for placeholder font size
  InputIcon: React.ElementType; // Type for the input icon component
  [key: string]: any; // Allow additional props
  px?: ResponsiveValue<string>;
}

export const InputWithIcon: React.FC<InputWithIconProps> = ({
  placeholder,
  width = '100%',
  height = '48px',
  iconSize = '24px',
  searchBarBgColor,
  searchBarTextColor,
  placeholderColor,
  iconColor = tinycolor(searchBarTextColor).lighten(30).toString(),
  onChange,
  value,
  placeholderFontFamily = 'Arial, sans-serif',
  placeholderFontSize = '16px',
  InputIcon,
  px,
}) => {
  return (
    <Box width={width} px={px} id="inputwithicon">
      <InputGroup>
        {/* Right aligned search icon */}
        <InputLeftElement pointerEvents="none" height={height}>
          <CustomIcon icon={InputIcon} size={iconSize} color={iconColor} padding="0 0 0 10px" />
        </InputLeftElement>
        {/* Input component with flexible height */}
        <CustomInput
          shadow="md"
          bgColor={searchBarBgColor}
          inputTextColor={searchBarTextColor}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          height={height} // Set height of the input
          paddingLeft="50px" // Add padding to avoid overlap with the right icon
          _placeholder={{
            fontFamily: placeholderFontFamily, // Font family for placeholder
            fontSize: placeholderFontSize, // Font size for placeholder
          }}
          fontSize="xl"
          placeholderColor={placeholderColor}
        />
      </InputGroup>
    </Box>
  );
};
