/* eslint-disable react/prop-types */ // Disabling prop-types check since we're using TypeScript
import { Menu, useDisclosure, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Importing Chevron icons from react-icons
import tinycolor from 'tinycolor2';

interface DropdownProps {
  label: string; // Label for the dropdown button
  items: string[]; // Array of items to display in the dropdown
  leftIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  onItemClick: (item: string) => void; // Function to handle item click
  textColor?: string;
  dropdownBgColor?: string;
  value: string;
}

export const CustomDropdown: React.FC<DropdownProps> = ({
  value,
  label,
  items,
  leftIcon,
  onItemClick,
  textColor = 'black',
  dropdownBgColor,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI hook for managing open/close state
  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      {' '}
      {/* Chakra manages outside clicks with onClose */}
      <MenuButton
        color={textColor}
        as={Button}
        leftIcon={leftIcon}
        variant="ghost"
        onClick={isOpen ? onClose : onOpen} // Toggle open/close
        rightIcon={isOpen ? <FaChevronUp /> : <FaChevronDown />} // Show up icon if open, down if closed
        value={value}
      >
        {label}
      </MenuButton>
      <MenuList backgroundColor={dropdownBgColor}>
        {items.map(item => (
          <MenuItem
            key={item}
            onClick={() => onItemClick(item)}
            backgroundColor={dropdownBgColor}
            _hover={{
              boxShadow: 'lg',
              backgroundColor: tinycolor(dropdownBgColor).lighten(20).toString(),
            }}
            textColor={textColor}
          >
            {item}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
