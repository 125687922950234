import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  HStack,
  Icon,
  Stack,
  Button,
  Image,
  Input,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  useToast,
  Center,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { CustomText } from '../../atoms';
import { ColorPickerAtom } from '../../atoms/ColorPickerAtom';
import { ToggleAtom } from '../../atoms/CustomToggle';

interface BannerSettingsMoleculeProps {
  configs: {
    bannerImageUrl: string;
    showBanner: boolean;
    showOverlay: boolean;
    showSearchBar: boolean;
    bannerBgColor: string;
    bannerTextColor: string;
    bannerTextSize?: string;
  };
  onConfigChange: (key: string, value: any) => void;
}

export const BannerSettingsMolecule: React.FC<BannerSettingsMoleculeProps> = ({
  configs,
  onConfigChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showOverlay, setShowOverlay] = useState(configs.showOverlay);
  const [showSearchBar, setShowSearchBar] = useState(configs.showSearchBar);
  const [showBanner, setShowBanner] = useState(configs.showBanner);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const toast = useToast();

  useEffect(() => {
    setShowOverlay(configs.showOverlay);
    setShowSearchBar(configs.showSearchBar);
    setShowBanner(configs.showBanner);
  }, [configs]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resetFileInput = () => {
      setSelectedFile(null);
      setPreviewUrl(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };

    const file = event.target.files?.[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        setAlertMessage('Only image files are allowed.');
        resetFileInput();
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setAlertMessage('File size must be less than 5MB.');
        resetFileInput();
        return;
      }

      const renamedFile = new File([file], 'banner.png', { type: file.type });
      setSelectedFile(renamedFile);

      const reader = new FileReader();
      reader.onloadend = () => setPreviewUrl(reader.result as string);
      reader.readAsDataURL(renamedFile);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const response = await fetch('YOUR_AZURE_FUNCTION_URL', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        toast({
          title: 'Upload successful!',
          description: 'Your banner image has been uploaded.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        onConfigChange('bannerImageUrl', data.fileUrl);
      } else {
        toast({
          title: 'Upload failed.',
          description: 'There was an issue uploading the file.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (error) {
      // console.error('Error uploading file:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while uploading.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box position="relative">
      <HStack justifyContent="space-between" p="4" borderRadius="lg" bg="gray.50" boxShadow="md">
        <CustomText variant="h1" size="md">
          Banner
        </CustomText>
        <Icon
          as={isExpanded ? ChevronUpIcon : ChevronDownIcon}
          boxSize={6}
          onClick={toggleExpandCollapse}
          cursor="pointer"
          aria-label={isExpanded ? 'Collapse' : 'Expand'}
        />
      </HStack>

      {isExpanded && (
        <Stack spacing={6} p={4} borderWidth="1px" borderRadius="lg" boxShadow="sm" mt={4}>
          <HStack spacing={4}>
            <Input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              ref={fileInputRef}
              bg="white"
            />
            <Button colorScheme="blue" onClick={handleUpload} isDisabled={!selectedFile}>
              Upload Banner
            </Button>
          </HStack>

          {previewUrl && (
            <Box mt={4} display="flex" justifyContent="center">
              <Image
                src={previewUrl}
                alt="Preview"
                boxSize="200px"
                borderRadius="md"
                objectFit="cover"
              />
            </Box>
          )}

          <Stack spacing={4}>
            <ToggleAtom
              label="Show Banner"
              value={showBanner}
              onChange={value => {
                setShowBanner(value);
                onConfigChange('showBanner', value);
              }}
            />
            <ToggleAtom
              label="Show Overlay"
              value={showOverlay}
              onChange={value => {
                setShowOverlay(value);
                onConfigChange('showOverlay', value);
              }}
            />
            <ToggleAtom
              label="Show SearchBar"
              value={showSearchBar}
              onChange={value => {
                setShowSearchBar(value);
                onConfigChange('showSearchBar', value);
              }}
            />
            <ColorPickerAtom
              label="Banner Background Color"
              value={configs.bannerBgColor}
              onChange={color => onConfigChange('bannerBgColor', color)}
            />
            <ColorPickerAtom
              label="Banner Text Color"
              value={configs.bannerTextColor}
              onChange={color => onConfigChange('bannerTextColor', color)}
            />
          </Stack>
        </Stack>
      )}

      {alertMessage && (
        <Center
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex="1000"
        >
          <Alert status="error" variant="solid" borderRadius="md" boxShadow="lg">
            <AlertIcon />
            <Box>
              <AlertTitle>Error</AlertTitle>
              {alertMessage}
            </Box>
            <CloseButton
              onClick={() => setAlertMessage(null)}
              position="absolute"
              right="8px"
              top="8px"
            />
          </Alert>
        </Center>
      )}
    </Box>
  );
};
