import React from 'react';
import { Box, Table, Thead, Tbody, Tr, ResponsiveValue } from '@chakra-ui/react';
import { CustomTableHeading } from '../atoms/CustomTableHeading';
import { CustomTableRow } from '../atoms/CustomTableRow';

export interface CustomTableProps {
  fields: string[]; // The field names to be displayed as headings
  data: object[];
  fieldsWithIcons?: string[];
  fieldsAsLinks?: string[];
  centerAlignFields?: string[];
  mt?: ResponsiveValue<string>;
  height?: ResponsiveValue<string>;
  rowBorderColor?: string;
}

export const CustomTable: React.FC<CustomTableProps> = ({
  fields,
  data,
  fieldsWithIcons,
  centerAlignFields,
  fieldsAsLinks,
  mt,
  height,
  rowBorderColor = 'gray',
}) => {
  return (
    <Box overflowX="auto" maxHeight={height} mt={mt} overflowY="auto">
      <Table width="100%" fontSize={{ base: 'sm', md: 'md', lg: 'md' }}>
        {/* Table Headings */}
        <Thead>
          <Tr position="sticky" top={0} zIndex={999}>
            {fields.map(field => (
              <CustomTableHeading
                key={btoa(field)}
                field={field}
                fieldsWithIcons={fieldsWithIcons}
                centerAlignFields={centerAlignFields}
              />
            ))}
          </Tr>
        </Thead>
        {/* Table Rows */}
        <Tbody>
          {data.map(item => (
            <CustomTableRow
              key={btoa(JSON.stringify(item))}
              fields={fields}
              item={item}
              fieldsAsLinks={fieldsAsLinks}
              rowBorderColor={rowBorderColor}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
